import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/CreatePushTemplateGroup.css';
import '../styles/Conditions.css';

const Templates = () => {
  const [groupName, setGroupName] = useState('');
  const [templates, setTemplates] = useState([
    { conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }
  ]);
  const [templateGroups, setTemplateGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchGroupTerm, setSearchGroupTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchTemplateGroups();
    fetchConditions();
  }, []);

  const fetchTemplateGroups = async () => {
    try {
      const res = await axios.get('/api/template-groups');
      setTemplateGroups(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке групп шаблонов', error);
    }
  };

  const fetchConditions = async () => {
    try {
      const res = await axios.get('/api/conditions');
      setConditions(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке условий', error);
    }
  };

  const handleTemplateChange = (index, field, value) => {
    const updatedTemplates = [...templates];
    updatedTemplates[index][field] = value;
    if (field === 'conditionId') {
      const condition = conditions.find(cond => cond._id === value);
      if (condition) {
        updatedTemplates[index]['conditionName'] = condition.name;
      }
    }
    setTemplates(updatedTemplates);
  };

  const handleAddTemplate = () => {
    setTemplates([...templates, { conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }]);
  };

  const handleRemoveTemplate = (index) => {
    const updatedTemplates = templates.filter((_, i) => i !== index);
    setTemplates(updatedTemplates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedGroup) {
        await axios.put(`/api/template-groups/${selectedGroup._id}`, { groupName, templates });
      } else {
        await axios.post('/api/template-groups', { groupName, templates });
      }
      setGroupName('');
      setTemplates([{ conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }]);
      fetchTemplateGroups();
      setIsFormVisible(false);
      setSelectedGroup(null);
    } catch (error) {
      console.log('Сохранение данных:', { groupName, templates });
      console.error('Ошибка при сохранении группы шаблонов', error);
    }
  };

  const handleEditGroup = (group) => {
    setGroupName(group.groupName);
    setTemplates(group.templates);
    setSelectedGroup(group);
    setIsFormVisible(true); // Показываем форму при редактировании
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await axios.delete(`/api/template-groups/${groupId}`);
      fetchTemplateGroups();
    } catch (error) {
      console.error('Ошибка при удалении группы шаблонов', error);
    }
  };

  const filteredTemplateGroups = templateGroups
    .filter(group => group.groupName.toLowerCase().includes(searchGroupTerm.toLowerCase())) // Фильтрация по названию
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(templateGroups.length / itemsPerPage);

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const filteredConditions = conditions.filter(condition =>
    condition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2>Управление шаблонами</h2>

      {/* Поле поиска по названию группы */}
      <input
        type="text"
        placeholder="Поиск по названию группы"
        value={searchGroupTerm}
        onChange={(e) => setSearchGroupTerm(e.target.value)}
        className="search-input"
      />

      <button onClick={() => setIsFormVisible(!isFormVisible)}>
        {isFormVisible ? 'Свернуть форму' : 'Создать новую группу шаблонов'}
      </button>

      {isFormVisible && (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Имя группы:</label>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />
          </div>

          {templates.map((template, index) => (
            <div key={index} className="template-item">
              <h4>Шаблон {index + 1}</h4>
              <div>
                <label>Условие:</label>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Поиск условия"
                />
                <select
                  value={template.conditionId}
                  onChange={(e) => handleTemplateChange(index, 'conditionId', e.target.value)}        
                >
                  <option value="">Выберите условие</option>
                  {filteredConditions.map(condition => (
                    <option key={condition._id} value={condition._id}>
                      {condition.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Ссылка на изображение:</label>
                <input
                  type="url"
                  value={template.imageUrl}
                  onChange={(e) => handleTemplateChange(index, 'imageUrl', e.target.value)}
                />
              </div>
              <div>
                <label>Заголовок:</label>
                <input
                  type="text"
                  value={template.title}
                  onChange={(e) => handleTemplateChange(index, 'title', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Текст:</label>
                <input
                  type="text"
                  value={template.text}
                  onChange={(e) => handleTemplateChange(index, 'text', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Кастомные данные (JSON):</label>
                <input
                  type="text"
                  value={template.customData}
                  onChange={(e) => handleTemplateChange(index, 'customData', e.target.value)}
                />
              </div>
              <button type="button" onClick={() => handleRemoveTemplate(index)}>Удалить шаблон</button>
            </div>
          ))}

          <button type="button" onClick={handleAddTemplate}>Добавить новый шаблон</button>
          <button type="submit">Сохранить</button>
        </form>
      )}

      <h3>Существующие группы шаблонов</h3>
      <ul className="conditions-list">
        {filteredTemplateGroups.map(group => (
          <li key={group._id}>
            {group.groupName}
            <button className = "condition-actions" onClick={() => handleEditGroup(group)}>Редактировать</button>
            <button className = "condition-actions" onClick={() => handleDeleteGroup(group._id)}>Удалить</button>
          </li>
        ))}
      </ul>

      <div className="pagination">
        <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>Назад</button>
        <span>Страница {currentPage} из {totalPages}</span>
        <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>Вперед</button>
      </div>
    </div>
  );
};

export default Templates;

