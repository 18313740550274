// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statisticsContainer {
    padding: 24px;
  }
  
  .filterForm {
    margin-top: 16px;
  }
  
  .formRow {
    margin-bottom: 8px;
  }
  
  .formLabel {
    margin-right: 8px;
    font-weight: 500;
  }
  
  .statList {
    list-style-type: none;
    padding-left: 0;
  }

  main.ant-layout-content.css-dev-only-do-not-override-240cud{
    display: grid;
    justify-items: center;
    justify-content: center;
  }
  button[type="button"]{
    color: #f0f0f0;
    background-color: #2868f1;
  }
  .statListItem {
    border: 2px solid #f0f0f0;
    padding: 8px;
    background: #fff;
    margin-bottom: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  
  /* Скрытый и показанный блок */
  .hidden {
    display: none;
  }
  
  .shown {
    display: block;
  }
  .ant-btn-color-link.ant-btn-variant-link {
    color: white; /* Переопределяем цвет */
    /* и любые другие нужные вам свойства */
    width: 40%;
  }
  
  .ant-space-align-center{
    display: inline-flex;
    align-items: baseline;
  }`, "",{"version":3,"sources":["webpack://./src/styles/StatisticsApps.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,qBAAqB;IACrB,uBAAuB;EACzB;EACA;IACE,cAAc;IACd,yBAAyB;EAC3B;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;EACxB;;EAEA,8BAA8B;EAC9B;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;EACA;IACE,YAAY,EAAE,wBAAwB;IACtC,uCAAuC;IACvC,UAAU;EACZ;;EAEA;IACE,oBAAoB;IACpB,qBAAqB;EACvB","sourcesContent":[".statisticsContainer {\r\n    padding: 24px;\r\n  }\r\n  \r\n  .filterForm {\r\n    margin-top: 16px;\r\n  }\r\n  \r\n  .formRow {\r\n    margin-bottom: 8px;\r\n  }\r\n  \r\n  .formLabel {\r\n    margin-right: 8px;\r\n    font-weight: 500;\r\n  }\r\n  \r\n  .statList {\r\n    list-style-type: none;\r\n    padding-left: 0;\r\n  }\r\n\r\n  main.ant-layout-content.css-dev-only-do-not-override-240cud{\r\n    display: grid;\r\n    justify-items: center;\r\n    justify-content: center;\r\n  }\r\n  button[type=\"button\"]{\r\n    color: #f0f0f0;\r\n    background-color: #2868f1;\r\n  }\r\n  .statListItem {\r\n    border: 2px solid #f0f0f0;\r\n    padding: 8px;\r\n    background: #fff;\r\n    margin-bottom: 8px;\r\n    border-radius: 4px;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  /* Скрытый и показанный блок */\r\n  .hidden {\r\n    display: none;\r\n  }\r\n  \r\n  .shown {\r\n    display: block;\r\n  }\r\n  .ant-btn-color-link.ant-btn-variant-link {\r\n    color: white; /* Переопределяем цвет */\r\n    /* и любые другие нужные вам свойства */\r\n    width: 40%;\r\n  }\r\n  \r\n  .ant-space-align-center{\r\n    display: inline-flex;\r\n    align-items: baseline;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
