import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Input,
  Select,
  Button,
  Space,
  Row,
  Col,
  Typography,
  Divider,
  Empty,
  Spin,
  ConfigProvider,
  Pagination,
  Statistic,
  Badge,
  Tag,
  message,
  Tooltip
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined,
  BarChartOutlined,
  FileTextOutlined,
  AppstoreOutlined,
  UserOutlined,
  PercentageOutlined,
  InfoCircleOutlined,
  FilterOutlined
} from '@ant-design/icons';
import '../styles/analytic-styles.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

// Кастомная тема для соответствия дизайну приложения
const customTheme = {
  token: {
    colorPrimary: '#1abc9c',
    colorError: '#e74c3c',
    borderRadius: 5,
  }
};

const StatisticsComponent = () => {
  const [statistics, setStatistics] = useState([]); // Вся статистика
  const [filteredStatistics, setFilteredStatistics] = useState([]); // Фильтрованная статистика
  const [conditions, setConditions] = useState([]); // Список условий
  const [selectedCondition, setSelectedCondition] = useState(""); 
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const itemsPerPage = 10;
  
  const team = localStorage.getItem('team');
  const role = localStorage.getItem('role');

  // Функция для получения статистики
  const fetchStatistics = async () => {
    setLoading(true);
    const today = new Date();
    const todayString = today.toISOString().replace(/T.*$/, '');
    try {
      const response = await axios.post("/api/statistics", {
        appName: "",
        geo: "",
        timeStart: "",
        timeEnd: todayString,
        team,
        role,
        offer: "",
      });
      setStatistics(response.data);
      setFilteredStatistics(response.data); // Изначально показываем всю статистику
    } catch (error) {
      console.error("Ошибка при получении статистики:", error);
      message.error("Не удалось загрузить статистику");
    } finally {
      setLoading(false);
    }
  };

  // Функция для получения условий
  const fetchConditions = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/get-conditions', {team, role});
      setConditions(response.data);
      setFilteredConditions(response.data);
    } catch (error) {
      console.error("Ошибка при получении условий:", error);
      message.error("Не удалось загрузить список условий");
    } finally {
      setLoading(false);
    }
  };

  // Получение фильтрованной статистики для текущей страницы
  const getPagedData = () => {
    const filtered = filteredStatistics.filter(stat => 
      stat.conditionName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filtered.slice(startIndex, endIndex);
  };

  const filteredData = getPagedData();
  const totalItems = filteredStatistics.filter(stat => 
    stat.conditionName.toLowerCase().includes(searchTerm.toLowerCase())
  ).length;

  // Обработчик поиска
  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = conditions.filter(condition =>
      condition.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredConditions(filtered);
    setCurrentPage(1); // Сбрасываем страницу при поиске
  };

  // Обработчик изменения выбранного условия
  const handleConditionChange = (value) => {
    setSelectedCondition(value);
    setCurrentPage(1); // Сбрасываем страницу при смене условия

    if (value === "") {
      setFilteredStatistics(statistics); // Если ничего не выбрано, показываем всю статистику
    } else {
      const filtered = statistics.filter(
        (stat) => stat.conditionName === value
      );
      setFilteredStatistics(filtered);
    }
  };

  useEffect(() => {
    fetchConditions();
    fetchStatistics();
  }, []);

  // Экспорт в CSV
  const exportToCSV = () => {
    if (!filteredData.length) {
      message.warning('Нет данных для экспорта');
      return;
    }
    
    setExportLoading(true);
    
    try {
      // Удаление ключа AppTeam из объектов
      const cleanedData = filteredData.map(item => {
        const { AppTeam, ...rest } = item;
        return rest;
      });
    
      // Получение заголовков (ключей объекта)
      const headers = Object.keys(cleanedData[0]).join(',');
    
      // Преобразование данных в строки CSV
      const rows = cleanedData.map(item =>
        Object.values(item)
          .map(value => (value === undefined || value === null || value === '' ? 'нет' : value))
          .map(value => `"${value}"`)
          .join(',')
      );
    
      // Объединение заголовков и строк данных
      const csvContent = [headers, ...rows].join('\n');
    
      // Создание и скачивание файла CSV
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
    
      link.setAttribute('href', url);
      link.setAttribute('download', 'analytics_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      message.success('Файл успешно скачан');
    } catch (error) {
      console.error('Ошибка при экспорте CSV:', error);
      message.error('Ошибка при экспорте данных');
    } finally {
      setExportLoading(false);
    }
  };

  // Определение цвета тега для процентов
  const getPercentageColor = (value) => {
    if (value >= 10) return 'success';
    if (value >= 5) return 'warning';
    return 'error';
  };

  // Отображение статистической карточки
  const renderStatCard = (stat, index) => {
    // Расчёт процентов
    const depPercentage = ((stat.depCount / (stat.pushCount || 1)) * 100).toFixed(2);
    const regPercentage = ((stat.regCount / (stat.pushCount || 1)) * 100).toFixed(2);

    return (
      <Card 
        key={index}
        className="stat-card"
        title={<Text strong>{stat.conditionName}</Text>}
        style={{ marginBottom: 16 }}
        extra={
          <Tag color="blue">{stat.appName || 'Нет данных'}</Tag>
        }
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card
              size="small"
              title={<Space><FileTextOutlined /> Содержание шаблона</Space>}
              style={{ height: '100%' }}
            >
              <Paragraph>
                <Text strong>Заголовок:</Text> {stat.templatetitle || 'Нет данных'}
              </Paragraph>
              <Paragraph>
                <Text strong>Текст:</Text> {stat.templatetext || 'Нет данных'}
              </Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card
              size="small"
              title={<Space><BarChartOutlined /> Показатели</Space>}
              style={{ height: '100%' }}
            >
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Statistic
                    title="Пуши"
                    value={stat.pushCount || 0}
                    valueStyle={{ fontSize: '18px' }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Депы"
                    value={stat.depCount || 0}
                    valueStyle={{ fontSize: '18px' }}
                  />
                  <div className="percentage-tag-container">
                    <Tag color={getPercentageColor(depPercentage)} className="percentage-tag">
                      {depPercentage}%
                    </Tag>
                  </div>
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Регистрации"
                    value={stat.regCount || 0}
                    valueStyle={{ fontSize: '18px' }}
                  />
                  <div className="percentage-tag-container">
                    <Tag color={getPercentageColor(regPercentage)} className="percentage-tag">
                      {regPercentage}%
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }} className="ant-card-wider">
        <Card 
          title={
            <Space>
              <BarChartOutlined style={{ fontSize: '20px' }} />
              <Title level={2} style={{ margin: 0 }}>Аналитика по условиям</Title>
            </Space>
          }
          style={{ marginBottom: '20px' }}
        >
          <div className="search-filter-row">
            <div className="search-filter-col">
              <Input
                placeholder="Поиск по названию условия..."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                allowClear
              />
            </div>
            <div className="search-filter-col">
              <Select
                placeholder="Выберите условие"
                value={selectedCondition}
                onChange={handleConditionChange}
                style={{ width: '100%' }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              >
                <Option value="">Все условия</Option>
                {filteredConditions.map((condition) => (
                  <Option key={condition._id} value={condition.name}>
                    {condition.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="button-col">
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={exportToCSV}
                loading={exportLoading}
                disabled={!filteredData.length}
                className="export-button"
              >
                Скачать CSV
              </Button>
            </div>
          </div>

          <Divider />

          {loading ? (
            <div style={{ textAlign: 'center', padding: '40px 0' }}>
              <Spin size="large" />
              <div style={{ marginTop: 16 }}>Загрузка данных...</div>
            </div>
          ) : filteredData.length > 0 ? (
            <div>
              <div className="statistics-panel">
                {filteredData.map((stat, index) => renderStatCard(stat, index))}
              </div>
              
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <Pagination
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={totalItems}
                  onChange={(page) => setCurrentPage(page)}
                  showSizeChanger={false}
                  showTotal={(total) => `Всего ${total} записей`}
                />
              </div>
            </div>
          ) : (
            <Empty 
              description="Нет данных для отображения" 
              style={{ margin: '40px 0' }}
            />
          )}
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default StatisticsComponent;