import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Table, 
  Input, 
  Button, 
  Space, 
  Card, 
  Form, 
  DatePicker, 
  Typography, 
  message, 
  Spin,
  ConfigProvider
} from 'antd';
import { 
  SearchOutlined, 
  FilterOutlined, 
  DownloadOutlined, 
  ReloadOutlined, 
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

// Упрощенная тема, чтобы избежать проблем с совместимостью
const customTheme = {
  token: {
    colorPrimary: '#e74c3c'
  }
};

const Statistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [searchStatTerm, setSearchStatTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [form] = Form.useForm();

  const team = localStorage.getItem('team');
  const role = localStorage.getItem('role');
  
  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await axios.post('/api/statistics', {
        appName: filters.appName || "",
        geo: filters.geo || "",
        timeStart: filters.timeStart || "",
        timeEnd: filters.timeEnd || "",
        team: team,
        role: role,
        offer: filters.offer || ""
      });
      setStatistics(response.data);
    } catch (error) {
      console.error('Ошибка при получении статистики:', error);
      message.error('Не удалось загрузить данные статистики');
    } finally {
      setLoading(false);
    }
  };

  const resetFilters = () => {
    form.resetFields();
    fetchStatistics();
    setIsFilterVisible(false);
  };

  const handleSearch = (value) => {
    setSearchStatTerm(value);
  };

  const handleFilterSubmit = (values) => {
    const filters = {
      appName: values.appName || "",
      geo: values.geo || "",
      offer: values.offer || ""
    };

    // Обработка начальной даты (если выбрана)
    if (values.startDate) {
      filters.timeStart = values.startDate.format('YYYY-MM-DD');
    }

    // Обработка конечной даты (если выбрана)
    if (values.endDate) {
      filters.timeEnd = values.endDate.format('YYYY-MM-DD');
    }

    fetchStatistics(filters);
    setIsFilterVisible(false);
  };

  const exportToCSV = () => {
    if (!filteredData.length) {
      message.warning('Нет данных для экспорта');
      return;
    }
    
    // Удаление ключа appName из объектов
    const cleanedData = filteredData.map(item => {
      const { appName, ...rest } = item;
      return rest;
    });
    
    // Получение заголовков (ключей объекта)
    const headers = Object.keys(cleanedData[0]).join(',');
    
    // Преобразование данных в строки CSV
    const rows = cleanedData.map(item =>
      Object.values(item)
        .map(value => (value === undefined || value === null || value === '' ? 'нет' : value))
        .map(value => `"${value}"`)
        .join(',')
    );
    
    // Объединение заголовков и строк данных
    const csvContent = [headers, ...rows].join('\n');
    
    // Создание и скачивание файла CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', 'statistics.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    message.success('Файл успешно скачан');
  };

  // Фильтрация данных по поисковому запросу
  const filteredData = statistics.filter(
    item => item.conditionName.toLowerCase().includes(searchStatTerm.toLowerCase())
  );

  // Определение колонок для таблицы в соответствии со скриншотом
  const columns = [
    {
      title: 'Условие',
      dataIndex: 'conditionName',
      key: 'conditionName',
      sorter: (a, b) => a.conditionName.localeCompare(b.conditionName)
    },
    {
      title: 'Группа шаблонов',
      dataIndex: 'templateName',
      key: 'templateName',
      sorter: (a, b) => a.templateName.localeCompare(b.templateName)
    },
    {
      title: 'Количество пушей',
      dataIndex: 'pushCount',
      key: 'pushCount',
      sorter: (a, b) => a.pushCount - b.pushCount
    },
    {
      title: 'Дата отправки',
      dataIndex: 'sentAt',
      key: 'sentAt',
      render: (text) => {
        if (!text) return 'Нет данных';
        // Проверка формата даты и форматирование при необходимости
        return text.includes('2024-') ? 
          `С ${text.split(' ')[0]} по ${text.split(' ')[text.split(' ').length - 1]}` : 
          text;
      },
      sorter: (a, b) => new Date(a.sentAt) - new Date(b.sentAt)
    },
    {
      title: 'Кол-во рег',
      dataIndex: 'regCount',
      key: 'regCount',
      sorter: (a, b) => a.regCount - b.regCount
    },
    {
      title: 'Кол-во кликов', // Новая колонка
      dataIndex: 'clickCount',
      key: 'clickCount',
      sorter: (a, b) => a.clickCount - b.clickCount
    },
    {
      title: 'Кол-во депов',
      dataIndex: 'depCount',
      key: 'depCount',
      sorter: (a, b) => a.depCount - b.depCount
    },
    {
      title: 'CTR, %',
      key: 'ctr',
      render: (record) => {
        const ctr = record.pushCount > 0 ? ((record.clickCount / record.pushCount) * 100).toFixed(2) : 0;
        return `${ctr}%`;
      },
      sorter: (a, b) => (a.clickCount / a.pushCount) - (b.clickCount / b.pushCount)
    }
  ];

  // Расширение данных для раскрываемой панели
  const expandedRowRender = (record) => (
    <Card bordered={false} style={{ background: '#f8f9fa' }}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <div>
          <Text strong>Заголовок:</Text> {record.templatetitle || 'Не указан'}
        </div>
        <div>
          <Text strong>Текст:</Text> {record.templatetext || 'Не указан'}
        </div>
        <div>
          <Text strong>CTR:</Text> {record.pushCount > 0 ? ((record.clickCount / record.pushCount) * 100).toFixed(2) : 0}%
        </div>
      </Space>
    </Card>
  );

  // Удаляем метод renderRangePicker, чтобы избежать проблем с dayjs

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ padding: '20px' }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>Статистика</Title>
          
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Input 
              placeholder="Поиск по названию условия" 
              value={searchStatTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 300 }}
              prefix={<SearchOutlined />}
              allowClear
            />
            <Space>
              <Button 
                type="primary" 
                icon={<FilterOutlined />} 
                onClick={() => setIsFilterVisible(!isFilterVisible)}
                style={{ background: '#e74c3c', borderColor: '#e74c3c' }}
              >
                Фильтр
              </Button>
              <Button 
                icon={<DownloadOutlined />}
                onClick={exportToCSV}
                disabled={filteredData.length === 0}
                style={{ background: '#e74c3c', borderColor: '#e74c3c', color: 'white' }}
              >
                Скачать CSV
              </Button>
              <Button 
                icon={<ReloadOutlined />}
                onClick={resetFilters}
                style={{ background: '#e74c3c', borderColor: '#e74c3c', color: 'white' }}
              >
                Сбросить
              </Button>
            </Space>
          </Space>
          
          {isFilterVisible && (
            <Card style={{ background: '#f8f9fa', marginBottom: '20px' }}>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleFilterSubmit}
              >
                <Form.Item name="appName" label="Название приложения">
                  <Input placeholder="Введите название приложения" style={{ background: 'white', border: '1px solid #d9d9d9' }} />
                </Form.Item>
                
                <Form.Item name="geo" label="Гео">
                  <Input placeholder="Введите гео" style={{ background: 'white', border: '1px solid #d9d9d9' }} />
                </Form.Item>
                
                <Form.Item name="startDate" label="Начальная дата">
                  <DatePicker 
                    format="YYYY-MM-DD"
                    placeholder="Выберите начальную дату"
                    style={{ width: '100%', background: 'white', border: '1px solid #d9d9d9' }}
                  />
                </Form.Item>
                
                <Form.Item name="endDate" label="Конечная дата">
                  <DatePicker 
                    format="YYYY-MM-DD"
                    placeholder="Выберите конечную дату"
                    style={{ width: '100%', background: 'white', border: '1px solid #d9d9d9' }}
                  />
                </Form.Item>
                
                <Form.Item name="offer" label="Offer">
                  <Input placeholder="Введите offer" style={{ background: 'white', border: '1px solid #d9d9d9' }} />
                </Form.Item>
                
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit" style={{ background: '#e74c3c', borderColor: '#e74c3c' }}>
                      Применить
                    </Button>
                    <Button onClick={() => setIsFilterVisible(false)} style={{ background: '#e74c3c', borderColor: '#e74c3c', color: 'white' }}>
                      Отмена
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          )}
          
          <Spin spinning={loading}>
            {statistics.length > 0 ? (
              <Table
                columns={columns}
                dataSource={filteredData}
                rowKey={(record) => record._id || Math.random().toString(36).substring(2, 11)}
                pagination={{ 
                  pageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '20', '50'],
                  showTotal: (total) => `Всего ${total} записей`,
                  size: 'small'
                }}
                style={{ backgroundColor: 'white' }}
              />
            ) : (
              <Card>
                <Text>Нет данных для отображения</Text>
              </Card>
            )}
          </Spin>
        </Space>
      </div>
    </ConfigProvider>
  );
};

export default Statistics;