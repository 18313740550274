import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css'; // Подключаем глобальные стили
import AppWrapper from './App'; // Импортируем AppWrapper вместо App
import { Provider } from 'react-redux';
import store from './redux/store'; // Подключаем хранилище redux

// Создаем корневой элемент приложения
const root = ReactDOM.createRoot(document.getElementById('root'));

// Рендерим приложение
root.render(
  <Provider store={store}>
    <AppWrapper /> {/* Используем AppWrapper, который включает Router */}
  </Provider>
);

