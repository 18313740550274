import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestPush = () => {
  const [users, setUsers] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedApp, setSelectedApp] = useState('');
  const [isSending, setIsSending] = useState(false);

  // Получаем список пользователей
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseTG = await axios.get('/api/telegram/users'); // Ваш маршрут для получения всех пользователей
        setUsers(responseTG.data);
        const responseAPA = await axios.get('/api/apa/users'); // Ваш маршрут для получения всех пользователей
        setUsers(responseAPA.data);
        console.log(users)
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchApps = async () => {
      try {
        const response = await axios.get('/api/apps'); // Ваш маршрут для получения всех приложений
        setApps(response.data);
      } catch (error) {
        console.error('Error fetching apps:', error);
      }
    };

    fetchUsers();
    fetchApps();
  }, []);

  // Функция для отправки тестового пуша
  const sendTestPush = async () => {
    if (!selectedUser || !selectedApp) return;
    setIsSending(true);
    try {
      await axios.post(`/api/test/send-test-push/${selectedUser}`, {
        appID: selectedApp
      });
      alert('Push sent successfully');
    } catch (error) {
      console.error('Error sending push:', error);
      alert('Error sending push');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div>
      <h2>Send Test Push Notification</h2>

      {/* Выбор пользователя */}
      <label htmlFor="userSelect">Select a user:</label>
      <select
        id="userSelect"
        value={selectedUser}
        onChange={(e) => setSelectedUser(e.target.value)}
      >
        <option value="">-- Select a user --</option>
        {users.map((user) => (
          <option key={user._id} value={user._id}>
            {user.username || user.af_id}
          </option>
        ))}
      </select>

      {/* Выбор приложения */}
      <label htmlFor="appSelect">Select an app:</label>
      <select
        id="appSelect"
        value={selectedApp}
        onChange={(e) => setSelectedApp(e.target.value)}
      >
        <option value="">-- Select an app --</option>
        {apps.map((app) => (
          <option key={app._id} value={app._id}>
            {app.name}
          </option>
        ))}
      </select>

      {/* Кнопка для отправки пуша */}
      <button onClick={sendTestPush} disabled={!selectedUser || !selectedApp || isSending}>
        {isSending ? 'Sending...' : 'Send Test Push'}
      </button>
    </div>
  );
};

export default TestPush;
