import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    const google = localStorage.getItem('google')
    if (google === "no") {
      localStorage.removeItem('google')
      } else {
      const response =  axios.get('https://api.pnsynd.com/auth/auth/logout', {
        withCredentials: true,
      });
      localStorage.removeItem('google');}
    navigate('/login');
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      Выход
    </button>
  );
};

export default Logout;



