import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Input,
  Button,
  Form,
  Select,
  Table,
  Space,
  Popconfirm,
  Typography,
  Divider,
  message,
  ConfigProvider,
  Row,
  Col,
  Collapse
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import '../styles/antd-custom.css';
const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

// Кастомная тема, соответствующая вашей цветовой схеме
const customTheme = {
  token: {
    colorPrimary: '#1abc9c',
    colorError: '#e74c3c',
    borderRadius: 5,
  },
  components: {
    Card: {
      colorBgContainer: '#1e293b',
      colorText: '#ffffff',
      colorTextHeading: '#ffffff',
    },
    Button: {
      colorPrimary: '#1abc9c',
      colorPrimaryHover: '#16a085',
    },
    Table: {
      colorBgContainer: '#1e293b',
      colorText: '#ffffff',
      headerBg: '#1e293b',
    },
    Select: {
      colorBgContainer: '#fff',
      colorBorder: '#ccc',
    },
    Input: {
      colorBgContainer: '#fff',
      colorBorder: '#ccc',
    },
    Form: {
      labelColor: '#ffffff',
    },
    Collapse: {
      colorBgContainer: '#34495e',
      colorText: '#ffffff',
      colorTextHeading: '#ffffff',
    },
  },
};

const Templates = () => {
  const [form] = Form.useForm();
  const [groupName, setGroupName] = useState('');
  const [templates, setTemplates] = useState([
    { conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }
  ]);
  const [templateGroups, setTemplateGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchGroupTerm, setSearchGroupTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTemplateGroups();
    fetchConditions();
  }, []);

  const fetchTemplateGroups = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/template-groups');
      setTemplateGroups(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке групп шаблонов', error);
      message.error('Не удалось загрузить группы шаблонов');
    } finally {
      setLoading(false);
    }
  };

  const fetchConditions = async () => {
    try {
      const res = await axios.get('/api/conditions');
      setConditions(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке условий', error);
      message.error('Не удалось загрузить условия');
    }
  };

  const handleTemplateChange = (index, field, value) => {
    const updatedTemplates = [...templates];
    updatedTemplates[index][field] = value;
    if (field === 'conditionId') {
      const condition = conditions.find(cond => cond._id === value);
      if (condition) {
        updatedTemplates[index]['conditionName'] = condition.name;
      }
    }
    setTemplates(updatedTemplates);
  };

  const handleAddTemplate = () => {
    setTemplates([...templates, { conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }]);
  };

  const handleRemoveTemplate = (index) => {
    const updatedTemplates = templates.filter((_, i) => i !== index);
    setTemplates(updatedTemplates);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (selectedGroup) {
        await axios.put(`/api/template-groups/${selectedGroup._id}`, { groupName, templates });
        message.success('Группа шаблонов успешно обновлена');
      } else {
        await axios.post('/api/template-groups', { groupName, templates });
        message.success('Группа шаблонов успешно создана');
      }
      setGroupName('');
      setTemplates([{ conditionId: '', conditionName: '', imageUrl: '', title: '', text: '', customData: '' }]);
      fetchTemplateGroups();
      setIsFormVisible(false);
      setSelectedGroup(null);
    } catch (error) {
      console.log('Сохранение данных:', { groupName, templates });
      console.error('Ошибка при сохранении группы шаблонов', error);
      message.error('Ошибка при сохранении группы шаблонов');
    } finally {
      setLoading(false);
    }
  };

  const handleEditGroup = (group) => {
    setGroupName(group.groupName);
    setTemplates(group.templates);
    setSelectedGroup(group);
    setIsFormVisible(true);
  };

  const handleDeleteGroup = async (groupId) => {
    setLoading(true);
    try {
      await axios.delete(`/api/template-groups/${groupId}`);
      message.success('Группа шаблонов успешно удалена');
      fetchTemplateGroups();
    } catch (error) {
      console.error('Ошибка при удалении группы шаблонов', error);
      message.error('Ошибка при удалении группы шаблонов');
    } finally {
      setLoading(false);
    }
  };

  const filteredTemplateGroups = templateGroups.filter(group => 
    group.groupName.toLowerCase().includes(searchGroupTerm.toLowerCase())
  );

  const filteredConditions = conditions.filter(condition =>
    condition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Определение колонок для таблицы
  const columns = [
    {
      title: 'Название группы',
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: (a, b) => a.groupName.localeCompare(b.groupName),
    },
    {
      title: 'Количество шаблонов',
      dataIndex: 'templates',
      key: 'templatesCount',
      render: (templates) => templates.length,
      sorter: (a, b) => a.templates.length - b.templates.length,
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => handleEditGroup(record)}
          >
            Редактировать
          </Button>
          <Popconfirm
            title="Вы уверены, что хотите удалить эту группу шаблонов?"
            onConfirm={() => handleDeleteGroup(record._id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
            >
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ padding: '20px' }}>
        <Card
          title={<Title level={2} style={{ margin: 0, color: 'white' }}>Управление шаблонами</Title>}
          extra={
            <Button
              type="primary"
              icon={isFormVisible ? <UpOutlined /> : <PlusOutlined />}
              onClick={() => setIsFormVisible(!isFormVisible)}
            >
              {isFormVisible ? 'Свернуть форму' : 'Создать новую группу шаблонов'}
            </Button>
          }
          style={{ marginBottom: '20px' }}
        >
          <Input
            placeholder="Поиск по названию группы"
            prefix={<SearchOutlined />}
            value={searchGroupTerm}
            onChange={(e) => setSearchGroupTerm(e.target.value)}
            style={{ marginBottom: '20px' }}
            allowClear
          />

          {isFormVisible && (
            <>
              <Divider style={{ margin: '10px 0 20px', borderColor: '#34495e' }} />
              <Form
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Form.Item
                  label="Имя группы"
                  rules={[{ required: true, message: 'Пожалуйста, введите имя группы' }]}
                >
                  <Input
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder="Введите имя группы"
                    required
                  />
                </Form.Item>

                <Collapse 
                  defaultActiveKey={['0']} 
                  style={{ marginBottom: '20px', background: '#34495e' }}
                >
                  {templates.map((template, index) => (
                    <Panel
                      header={`Шаблон ${index + 1}`}
                      key={index}
                      extra={
                        <Button
                          type="text"
                          danger
                          icon={<MinusCircleOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveTemplate(index);
                          }}
                        />
                      }
                    >
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label="Условие"
                            required
                          >
                            <Input
                              placeholder="Поиск условия"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              style={{ marginBottom: '10px' }}
                            />
                            <Select
                              style={{ width: '100%' }}
                              value={template.conditionId}
                              onChange={(value) => handleTemplateChange(index, 'conditionId', value)}
                              placeholder="Выберите условие"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              showSearch
                            >
                              <Option value="">Выберите условие</Option>
                              {filteredConditions.map(condition => (
                                <Option key={condition._id} value={condition._id}>
                                  {condition.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Ссылка на изображение"
                          >
                            <Input
                              type="url"
                              value={template.imageUrl}
                              onChange={(e) => handleTemplateChange(index, 'imageUrl', e.target.value)}
                              placeholder="Введите URL изображения"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Заголовок"
                            required
                          >
                            <Input
                              value={template.title}
                              onChange={(e) => handleTemplateChange(index, 'title', e.target.value)}
                              placeholder="Введите заголовок"
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Текст"
                            required
                          >
                            <Input
                              value={template.text}
                              onChange={(e) => handleTemplateChange(index, 'text', e.target.value)}
                              placeholder="Введите текст"
                              required
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Кастомные данные (JSON)"
                          >
                            <Input
                              value={template.customData}
                              onChange={(e) => handleTemplateChange(index, 'customData', e.target.value)}
                              placeholder="Введите кастомные данные в формате JSON"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  ))}
                </Collapse>

                <Button
                  type="dashed"
                  onClick={handleAddTemplate}
                  style={{ width: '100%', marginBottom: '20px' }}
                  icon={<PlusCircleOutlined />}
                >
                  Добавить новый шаблон
                </Button>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {selectedGroup ? 'Обновить группу' : 'Сохранить группу'}
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>

        <Card title={<Title level={3} style={{ margin: 0, color: 'white' }}>Существующие группы шаблонов</Title>}>
          <Table
            columns={columns}
            dataSource={filteredTemplateGroups.map(item => ({ ...item, key: item._id }))}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              onChange: setCurrentPage,
              onShowSizeChange: (current, size) => {
                setCurrentPage(1);
                setPageSize(size);
              },
              pageSizeOptions: ['10', '20', '50'],
              showSizeChanger: true,
              showTotal: (total) => `Всего ${total} записей`,
            }}
            loading={loading}
            size="middle"
          />
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default Templates;