import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import {
  Card,
  Input,
  Button,
  Form,
  Select,
  InputNumber,
  Table,
  Space,
  Popconfirm,
  Typography,
  Divider,
  message,
  ConfigProvider
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import '../styles/antd-custom.css';

const { Title, Text } = Typography;
const { Option } = Select;

// Кастомная тема, соответствующая вашей цветовой схеме
const customTheme = {
  token: {
    colorPrimary: '#1abc9c',
    colorError: '#e74c3c',
    borderRadius: 5,
  },
  components: {
    Card: {
      colorBgContainer: '#1e293b',
      colorText: '#ffffff',
      colorTextHeading: '#ffffff',
    },
    Button: {
      colorPrimary: '#1abc9c',
      colorPrimaryHover: '#16a085',
    },
    Table: {
      colorBgContainer: '#1e293b',
      colorText: '#ffffff',
      headerBg: '#1e293b',
    },
    Select: {
      colorBgContainer: '#fff',
      colorBorder: '#ccc',
    },
    Input: {
      colorBgContainer: '#fff',
      colorBorder: '#ccc',
    },
    Form: {
      labelColor: '#ffffff',
    },
  },
};

const Conditions = () => {
  const authContext = useContext(AuthContext);
  const token = authContext ? authContext.token : null;
  const [form] = Form.useForm();

  const [conditions, setConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [apps, setApps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchConditionTerm, setSearchConditionTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const fetchApps = useCallback(async () => {
    try {
      const res = await axios.get('/api/apps');
      setApps(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке списка приложений', error);
      message.error('Не удалось загрузить список приложений');
    }
  }, []);

  const fetchConditions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/conditions');
      setConditions(res.data);
    } catch (error) {
      console.error('Ошибка при загрузке условий', error);
      message.error('Не удалось загрузить условия');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchApps();
    fetchConditions();
  }, [fetchApps, fetchConditions]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const conditionData = {
      name: values.conditionName,
      type: values.type,
      cronExpression: values.cronExpression,
      trackingEventInitial: values.trackingEventInitial,
      trackingEventFulfilled: values.trackingEventFulfilled,
      delay: values.delay,
      os: values.os,
      appId: values.appId,
      offer: values.offer,
      countries: values.countries,
      flow: values.flow,
      team: values.team
    };
    
    try {
      if (selectedCondition) {
        await axios.put(`/api/conditions/${selectedCondition._id}`, conditionData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('Условие успешно обновлено');
      } else {
        await axios.post('/api/conditions', conditionData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        message.success('Условие успешно создано');
      }
      resetForm();
      fetchConditions();
      setIsFormVisible(false);
    } catch (error) {
      console.error('Ошибка при сохранении условия', error);
      message.error('Ошибка при сохранении условия');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedCondition(null);
  };

  const handleEditCondition = (condition) => {
    setSelectedCondition(condition);
    form.setFieldsValue({
      conditionName: condition.name,
      type: condition.type,
      cronExpression: condition.cronExpression,
      trackingEventInitial: condition.trackingEventInitial,
      trackingEventFulfilled: condition.trackingEventFulfilled,
      delay: condition.delay,
      os: condition.os,
      appId: condition.appId,
      offer: condition.offer,
      countries: condition.countries,
      flow: condition.flow,
      team: condition.team
    });
    setIsFormVisible(true);
  };

  const handleDeleteCondition = async (conditionId) => {
    setLoading(true);
    try {
      await axios.delete(`/api/conditions/${conditionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      message.success('Условие успешно удалено');
      fetchConditions();
    } catch (error) {
      console.error('Ошибка при удалении условия', error);
      message.error('Ошибка при удалении условия');
    } finally {
      setLoading(false);
    }
  };

  const filteredConditions = conditions.filter(condition => 
    condition.name.toLowerCase().includes(searchConditionTerm.toLowerCase())
  );

  const tableContainerStyle = {
    width: '100%',
    overflowX: 'auto',  // Добавляет горизонтальную прокрутку при необходимости
  };
  
  // 2. Обновите стили для таблицы
  const tableStyle = {
    width: '100%',
    tableLayout: 'fixed',  // Фиксированная ширина столбцов
  };

  
  
  // 3. Обновите определение колонок, добавив ширину
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '30%',  // Фиксированная ширина в процентах
      ellipsis: true, // Обрезать слишком длинный текст с многоточием
    },
    {
      title: 'Приложение',
      dataIndex: 'appId',
      key: 'appId',
      render: (appId) => appId?.name || "Без приложения",
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      width: '10%',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: '25%',
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EditOutlined />}
            size="small"
            onClick={() => handleEditCondition(record)}
          >
            Редактировать
          </Button>
          <Popconfirm
            title="Вы уверены, что хотите удалить это условие?"
            onConfirm={() => handleDeleteCondition(record._id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
            >
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ padding: '20px' }}>
        <Card
          title={<Title level={2} style={{ margin: 0, color: 'white' }}>Условия</Title>}
          extra={
            <Button
              type="primary"
              icon={isFormVisible ? <UpOutlined /> : <PlusOutlined />}
              onClick={() => setIsFormVisible(!isFormVisible)}
            >
              {isFormVisible ? 'Свернуть форму' : 'Создать условие'}
            </Button>
          }
          style={{ marginBottom: '20px' }}
        >
          <Input
            placeholder="Поиск по названию условия"
            prefix={<SearchOutlined />}
            value={searchConditionTerm}
            onChange={(e) => setSearchConditionTerm(e.target.value)}
            style={{ marginBottom: '20px' }}
            allowClear
          />

          {isFormVisible && (
            <>
              <Divider style={{ margin: '10px 0 20px', borderColor: '#34495e' }} />
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ delay: 0 }}
              >
                <Form.Item
                  name="conditionName"
                  label="Название условия"
                  rules={[{ required: true, message: 'Пожалуйста, введите название условия' }]}
                >
                  <Input placeholder="Введите название условия" />
                </Form.Item>

                <Form.Item
                  name="type"
                  label="Тип пуша"
                  rules={[{ required: true, message: 'Пожалуйста, выберите тип пуша' }]}
                >
                  <Select placeholder="Выберите тип пуша">
                    <Option value="regular">Regular</Option>
                    <Option value="event">Event</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="cronExpression"
                  label="Cron"
                >
                  <Input placeholder="[Регулярный] Шаблон для регулярных пушей" />
                </Form.Item>

                <Form.Item
                  name="trackingEventInitial"
                  label="Инициирующее событие"
                >
                  <Select placeholder="Выберите инициирующее событие">
                    <Option value="af_purchase">af_purchase</Option>
                    <Option value="firstLogin">firstLogin</Option>
                    <Option value="login">login</Option>
                    <Option value="pushClick">pushClick</Option>
                    <Option value="pushReceive">pushReceive</Option>
                    <Option value="registration">registration</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="trackingEventFulfilled"
                  label="Завершающее событие"
                  help="(не обязательно) Событие после которого необходимо отменить отправку пуша"
                >
                  <Select placeholder="Выберите завершающее событие">
                    <Option value="af_purchase">Депозит</Option>
                    <Option value="firstLogin">Установка</Option>
                    <Option value="login">Логин</Option>
                    <Option value="pushClick">Клик по пушу</Option>
                    <Option value="pushReceive">pushReceive</Option>
                    <Option value="registration">Регистрация</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="delay"
                  label="Задержка (в секундах)"
                >
                  <InputNumber style={{ width: '100%' }} min={0} />
                </Form.Item>

                <Form.Item
                  name="os"
                  label="OS"
                >
                  <Select placeholder="Выберите OS">
                    <Option value="iOS">iOS</Option>
                    <Option value="Android">Android</Option>
                    <Option value="PWA">PWA</Option>
                    <Option value="Telegram">Telegram</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="appId"
                  label="Приложение"
                >
                  <Select
                    showSearch
                    placeholder="Выберите приложение"
                    optionFilterProp="children"
                    onChange={(value) => form.setFieldsValue({ appId: value })}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {apps.map(app => (
                      <Option key={app._id} value={app._id}>{app.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="offer"
                  label="Оффер"
                >
                  <Input placeholder="Укажите оффер" />
                </Form.Item>

                <Form.Item
                  name="countries"
                  label="Страны"
                >
                  <Input placeholder="Укажите страны" />
                </Form.Item>

                <Form.Item
                  name="flow"
                  label="Поток"
                >
                  <Input placeholder="Укажите поток" />
                </Form.Item>

                <Form.Item
                  name="team"
                  label="Команда"
                >
                  <Input placeholder="Укажите команду" />
                </Form.Item>

                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      {selectedCondition ? 'Обновить' : 'Сохранить'}
                    </Button>
                    {selectedCondition && (
                      <Button onClick={resetForm}>
                        Очистить форму
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Form>
            </>
          )}
        </Card>

        <Card title={<Title level={3} style={{ margin: 0, color: 'white' }}>Существующие условия</Title>}>
        <div style={tableContainerStyle}>
        <Table
          columns={columns}
          dataSource={filteredConditions.map(item => ({ ...item, key: item._id }))}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: setCurrentPage,
            onShowSizeChange: (current, size) => {
              setCurrentPage(1);
              setPageSize(size);
            },
            pageSizeOptions: ['10', '20', '50'],
            showSizeChanger: true,
            showTotal: (total) => `Всего ${total} записей`,
          }}
          loading={loading}
          size="middle"
          style={tableStyle}
          scroll={{ x: 800 }}  // Минимальная ширина содержимого таблицы
        />
      </div>
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default Conditions;