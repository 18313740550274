import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Task.css';


const TaskManager = () => {
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState({ name: '', text: '' });
    const [editingTask, setEditingTask] = useState(null);
    const [editValues, setEditValues] = useState({ name: '', text: '' });

    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userID');

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        try {
            const response = await axios.get('/api/task', {
                params: { userID: userId, role }
            });
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching tasks', error);
        }
    };

    const createTask = async () => {
        try {
            const response = await axios.post('/api/task', {
                name: newTask.name,
                text: newTask.text,
                userID: userId
            });
            setTasks(prev => [...prev, response.data.task]);
            setNewTask({ name: '', text: '' });
        } catch (error) {
            console.error('Error creating task', error);
        }
    };

    const updateTask = async (taskId, updates) => {
        try {
            const response = await axios.put(`/api/task/${taskId}`, {
                ...updates,
                role
            });

            // Обновляем локальное состояние задач
            setTasks(prev =>
                prev.map(task =>
                    task._id === taskId ? { ...task, ...response.data.task } : task
                )
            );

            // Закрываем режим редактирования
            setEditingTask(null);
        } catch (error) {
            console.error('Error updating task', error);
        }
    };

    const deleteTask = async (taskId) => {
        try {
            await axios.delete(`/api/task/${taskId}`, { data: { role } });
            setTasks(prev => prev.filter(task => task._id !== taskId));
        } catch (error) {
            console.error('Error deleting task', error);
        }
    };

    const renderTask = (task, completed) => {
        if (editingTask === task._id) {
            return (
                <div key={task._id} className="task-card editing">
                    <input
                        type="text"
                        value={editValues.name}
                        onChange={e => setEditValues({ ...editValues, name: e.target.value })}
                        placeholder="Новое название"
                        className="input"
                        style={{padding:5}}
                    />
                    <textarea
                        value={editValues.text}
                        onChange={e => setEditValues({ ...editValues, text: e.target.value })}
                        placeholder="Новый текст"
                        className="textarea"
                    ></textarea>
                    <button onClick={() => updateTask(task._id, { name: editValues.name, text: editValues.text })}>
                        Сохранить
                    </button>
                    <button onClick={() => setEditingTask(null)}>Отмена</button>
                </div>
            );
        }

        return (
            <div key={task._id} className={`task-card ${completed ? 'completed' : ''}`}>
                <h4 className="task-title">{task.name}</h4>
                <p className="task-text">{task.text}</p>
                {!completed && role !== 'admin' && (
                    <button
                        onClick={() => {
                            setEditingTask(task._id);
                            setEditValues({ name: task.name, text: task.text });
                        }}
                    >
                        Изменить
                    </button>
                )}
                {(role === 'admin' || role === 'editor') && (
                    <button
                        onClick={() => updateTask(task._id, { complete: !completed })}
                    >
                        {completed ? 'Отметить как незавершенное' : 'Отметить как завершенное'}
                    </button>
                )}
                {role === 'admin' && <button onClick={() => deleteTask(task._id)}>Удалить</button>}
            </div>
        );
    };

    const incompleteTasks = tasks.filter(task => !task.complete);
    const completeTasks = tasks.filter(task => task.complete);

    return (
        <div className="task-manager" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
            <div>
                <h3>{role === 'admin' || role === 'editor' ? 'Незавершенные задачи' : 'Ваши задачи'}</h3>
                {role === 'user' && (
                    <div className="task-card">
                        <input
                            className="input-task"
                            type="text"
                            placeholder="Название"
                            value={newTask.name}
                            onChange={e => setNewTask({ ...newTask, name: e.target.value })}
                            style={{padding:5}}
                        />
                        <textarea
                        
                            placeholder="Текст задачи"
                            value={newTask.text}
                            onChange={e => setNewTask({ ...newTask, text: e.target.value })}
                            className="textarea"
                        ></textarea>
                        <button onClick={createTask}>Создать задачу</button>
                    </div>
                )}
                {incompleteTasks.map(task => renderTask(task, false))}
            </div>
            <div>
                <h3>Завершенные задачи</h3>
                {completeTasks.map(task => renderTask(task, true))}
            </div>
        </div>
    );
};

export default TaskManager;
