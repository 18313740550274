import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  Button,
  Card,
  Space,
  Tag,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Popconfirm,
  message,
  Typography,
  Divider
} from 'antd';
import {
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  LockOutlined
} from '@ant-design/icons';

import '../styles/UserManagement.css';

const { Title, Text } = Typography;
const { Option } = Select;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  // Данные для формы добавления нового пользователя
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [addForm] = Form.useForm();

  // Данные для формы редактирования
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [currentUser, setCurrentUser] = useState(null);

  // Поля для смены пароля
  const [passwords, setPasswords] = useState({ oldPassword: '', newPassword: '' });

  // Токен
  const yourToken = localStorage.getItem('token');

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/users', {
        headers: { Authorization: `Bearer ${yourToken}` },
      });
      setUsers(res.data);
    } catch (err) {
      console.error('Ошибка при загрузке пользователей', err);
      message.error('Не удалось загрузить список пользователей');
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = () => {
    // Открываем модалку для добавления
    setIsAddModalVisible(true);
  };

  const onAddUserFinish = async (values) => {
    try {
      setLoading(true);
      const res = await axios.post('/api/users', values, {
        headers: { Authorization: `Bearer ${yourToken}` },
      });
      setUsers([...users, res.data]);
      message.success('Пользователь добавлен успешно');
      addForm.resetFields();
      setIsAddModalVisible(false);
    } catch (err) {
      console.error('Ошибка при добавлении пользователя', err);
      message.error('Не удалось добавить пользователя');
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
    // Открываем модалку для редактирования, заполняем форму текущими данными
    setCurrentUser(user);
    editForm.setFieldsValue({
      role: user.role,
      team: user.team || '',
      statisticsAccess: !!user.statisticsAccess,
    });
    setIsEditModalVisible(true);
  };

  const onEditUserFinish = async (values) => {
    if (!currentUser) return;
    try {
      setLoading(true);
      // Собираем поля, которые нужно обновить
      const updatedData = {
        role: values.role,
        team: values.role === 'user' ? values.team : '',
        statisticsAccess: values.statisticsAccess,
      };

      const res = await axios.put(`/api/users/${currentUser._id}`, updatedData, {
        headers: { Authorization: `Bearer ${yourToken}` },
      });

      // Обновляем локальный стейт
      setUsers((prev) =>
        prev.map((item) => (item._id === currentUser._id ? res.data : item))
      );
      message.success('Изменения сохранены');
      setIsEditModalVisible(false);
      setCurrentUser(null);
    } catch (err) {
      console.error('Ошибка при обновлении пользователя', err);
      message.error('Не удалось сохранить изменения');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/users/${id}`, {
        headers: { Authorization: `Bearer ${yourToken}` },
      });
      setUsers(users.filter((user) => user._id !== id));
      message.success('Пользователь удалён');
    } catch (err) {
      console.error('Ошибка при удалении пользователя', err);
      message.error('Не удалось удалить пользователя');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!currentUser) return;
    try {
      setLoading(true);
      const res = await axios.put(
        `/api/users/${currentUser._id}/password`,
        passwords,
        { headers: { Authorization: `Bearer ${yourToken}` } }
      );
      message.success(res.data.message || 'Пароль успешно изменён');
      setPasswords({ oldPassword: '', newPassword: '' });
    } catch (err) {
      console.error('Ошибка при смене пароля', err);
      message.error('Не удалось сменить пароль');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Логин',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        let color = 'blue';
        if (role === 'admin') color = 'red';
        if (role === 'editor') color = 'geekblue';
        return <Tag color={color}>{role.toUpperCase()}</Tag>;
      },
    },
    {
      title: 'Команда',
      dataIndex: 'team',
      key: 'team',
      render: (team) => team || '—',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Изменить
          </Button>
          <Popconfirm
            title="Удалить пользователя?"
            onConfirm={() => handleDeleteUser(record._id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button danger icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-management-container">
      <Card
        title={
          <Space align="center">
            <UserOutlined />
            <Title level={3} style={{ margin: 0 }}>
              Управление пользователями
            </Title>
          </Space>
        }
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Добавить
          </Button>
        }
        style={{ width: '100%' }}
      >
        <Table
          dataSource={users}
          columns={columns}
          rowKey="_id"
          loading={loading}
          pagination={{ pageSize: 8 }}
        />
      </Card>

      {/* Модалка для добавления нового пользователя */}
      <Modal
        title="Добавить пользователя"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <Form
          form={addForm}
          layout="vertical"
          onFinish={onAddUserFinish}
          initialValues={{ role: 'user', team: '' }}
        >
          <Form.Item
            label="Логин"
            name="username"
            rules={[{ required: true, message: 'Введите логин' }]}
          >
            <Input placeholder="Введите логин" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Введите email' }]}
          >
            <Input placeholder="Введите email" />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Введите пароль' }]}
          >
            <Input.Password placeholder="Введите пароль" />
          </Form.Item>

          <Form.Item label="Роль" name="role">
            <Select>
              <Option value="user">User</Option>
              <Option value="editor">Editor</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </Form.Item>

          {/* Показывать поле "Команда" только если роль — user */}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return getFieldValue('role') === 'user' ? (
                <Form.Item label="Команда" name="team">
                  <Input placeholder="Введите название команды" />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>

          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setIsAddModalVisible(false)} style={{ marginRight: 8 }}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Добавить
            </Button>
          </div>
        </Form>
      </Modal>

      {/* Модалка для редактирования пользователя */}
      <Modal
        title="Редактировать пользователя"
        visible={isEditModalVisible}
        onCancel={() => {
          setIsEditModalVisible(false);
          setCurrentUser(null);
        }}
        footer={null}
        destroyOnClose
      >
        <Form
          form={editForm}
          layout="vertical"
          onFinish={onEditUserFinish}
        >
          <Form.Item label="Роль" name="role">
            <Select>
              <Option value="user">User</Option>
              <Option value="editor">Editor</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </Form.Item>

          {/* Аналогично показываем "Команду" только если роль user */}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('role') === 'user' ? (
                <Form.Item label="Команда" name="team">
                  <Input placeholder="Введите название команды" />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            label="Доступ к статистике"
            name="statisticsAccess"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Divider />

          <Title level={5}>Сменить пароль</Title>
          <Form.Item label="Старый пароль">
            <Input.Password
              placeholder="Старый пароль"
              value={passwords.oldPassword}
              onChange={(e) =>
                setPasswords((prev) => ({ ...prev, oldPassword: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Новый пароль">
            <Input.Password
              placeholder="Новый пароль"
              value={passwords.newPassword}
              onChange={(e) =>
                setPasswords((prev) => ({ ...prev, newPassword: e.target.value }))
              }
            />
          </Form.Item>
          <Button
            icon={<LockOutlined />}
            onClick={handleChangePassword}
            style={{ marginBottom: 16 }}
          >
            Сменить пароль
          </Button>

          <Divider />

          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                setIsEditModalVisible(false);
                setCurrentUser(null);
              }}
              style={{ marginRight: 8 }}
            >
              Отмена
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Сохранить
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagement;
