import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { 
  Layout, 
  Select, 
  Card, 
  Row, 
  Col, 
  Typography, 
  Statistic, 
  Spin, 
  Empty, 
  Divider,
  Button,
  Badge,
  Alert,
  Space,
  Tooltip
} from 'antd';
import { 
  DashboardOutlined, 
  LineChartOutlined, 
  PieChartOutlined, 
  CalendarOutlined,
  UserOutlined,
  ReloadOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  BellOutlined
} from '@ant-design/icons';
import Sidebar from './Sidebar';
import Templates from './Templates';
import Conditions from './Conditions';
import UsersManagement from './UsersManagement';
import Statistics from './Statistics';
import Logs from './Logs';
import Apps from './Apps';
import '../styles/Dashboard.css';
import TestPush from './TestPush';
import axios from 'axios';
import PushPieChart from './PushPieChart';
import StatisticsApps from './StatisticsApps';
import TaskManager from './Task';
import StatisticsComponent from './Analytic';
import ConditionComparison from './Compare';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const Dashboard = ({ role, username }) => {
  const [panelCount, setPanelCount] = useState(2);
  const [conditions, setConditions] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedApps, setSelectedApps] = useState({});
  const [statistics, setStatistics] = useState([]);
  const [appStatistics, setAppStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState({});
  const location = useLocation();
  const team = localStorage.getItem('team');

  // Преобразуем опции для Ant Design Select
  const conditionOptions = conditions.map(condition => ({
    value: condition._id,
    label: condition.name
  }));

  const appOptions = apps.map(app => ({
    value: app.appName,
    label: app.appName
  }));

  useEffect(() => {
    // Функция для загрузки всех данных
    fetchAllData();
  }, [team, role]);

  const fetchAllData = async () => {
    try {
      setLoading(true);
      
      // Параллельно загружаем все данные
      const [conditionsRes, appsRes, statisticsRes, appStatisticsRes] = await Promise.all([
        axios.post('/api/get-conditions', { team, role }),
        axios.post('/api/statapp/statistics', { geo: "", timeStart: "", timeEnd: "", team, role, offer: "" }),
        axios.post('/api/statistics', { appName: "", geo: "", timeStart: "", timeEnd: "", team, role, offer: "" }),
        axios.post('/api/statapp/statistics', { geo: "", timeStart: "", timeEnd: "", team, role, offer: "" })
      ]);

      setConditions(conditionsRes.data);
      setApps(appsRes.data);
      setStatistics(statisticsRes.data);
      setAppStatistics(appStatisticsRes.data);

      // Загружаем сохраненные данные из localStorage
      const savedSelectedConditions = JSON.parse(localStorage.getItem('selectedConditions')) || {};
      const savedSelectedApps = JSON.parse(localStorage.getItem('selectedApps')) || {};
      
      setSelectedConditions(savedSelectedConditions);
      setSelectedApps(savedSelectedApps);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      setLoading(false);
    }
  };

  // Сохраняем выбранные условия и приложения в localStorage
  useEffect(() => {
    localStorage.setItem('selectedConditions', JSON.stringify(selectedConditions));
  }, [selectedConditions]);

  useEffect(() => {
    localStorage.setItem('selectedApps', JSON.stringify(selectedApps));
  }, [selectedApps]);

  const fetchConditionsByApp = async (appName, index) => {
    try {
      setRefreshing(prev => ({ ...prev, [index]: true }));
      const response = await axios.post('/api/get-conditions', { team, role });
      
      let conds = response.data;
      if (appName) {
        conds = conds.filter(cond => cond.appId.name === appName);
      }
      setConditions(conds);
    } catch (error) {
      console.error('Ошибка при получении условий:', error);
    } finally {
      setRefreshing(prev => ({ ...prev, [index]: false }));
    }
  };

  const handlePanelCountChange = (value) => {
    setPanelCount(Number(value));
  };

  const handleConditionChange = (value, index) => {
    setSelectedConditions(prev => ({ ...prev, [index]: value }));
  };

  const handleAppChange = (value, index) => {
    fetchConditionsByApp(value, index);
    setSelectedApps(prev => ({ ...prev, [index]: value }));
  };

  const refreshPanel = (index) => {
    const appName = selectedApps[index];
    fetchConditionsByApp(appName, index);
  };

  // Генерируем панели на основе выбранного количества
  const renderPanels = () => {
    return Array.from({ length: panelCount }, (_, index) => {
      const selectedConditionId = selectedConditions[index];
      const selectedAppName = selectedApps[index];
      
      const conditionName = conditions.find(cond => cond._id === selectedConditionId)?.name;
      
      let filteredStatistics = null;
      
      if (selectedAppName && conditionName) {
        filteredStatistics = statistics.find(stat => 
          stat.conditionName === conditionName
        );
      } else if (selectedAppName) {
        filteredStatistics = appStatistics.find(stat => 
          stat.appName === selectedAppName
        );
      } else if (conditionName) {
        filteredStatistics = statistics.find(stat => 
          stat.conditionName === conditionName
        );
      }

      const isRefreshing = refreshing[index] || false;

      return (
        <Col key={index} xs={24} md={12} lg={panelCount <= 4 ? 12 : 8} xl={panelCount <= 4 ? 12 : 8} xxl={6}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title level={5} style={{ margin: 0 }}>
                  Дашборд #{index + 1}
                </Title>
                <Tooltip title="Обновить данные">
                  <Button 
                    type="text" 
                    icon={<ReloadOutlined spin={isRefreshing} />} 
                    onClick={() => refreshPanel(index)}
                    size="small"
                  />
                </Tooltip>
              </div>
            }
            style={{ 
              marginBottom: 16, 
              height: '100%',
              boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
            }}
            className="dashboard-card"
            hoverable
          >
            <div className="card-selectors">
              <div style={{ marginBottom: 16 }}>
                <Space>
                  <Text>Условие:</Text>
                  <Tooltip title="Выберите условие для отображения статистики">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                  </Tooltip>
                </Space>
                <Select
                  style={{ width: '100%', marginTop: 8 }}
                  value={selectedConditionId}
                  onChange={(value) => handleConditionChange(value, index)}
                  placeholder="Выберите условие"
                  allowClear
                  showSearch
                  optionFilterProp="label"
                >
                  {conditionOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              
              <div style={{ marginBottom: 16 }}>
                <Space>
                  <Text>Приложение:</Text>
                  <Tooltip title="Выберите приложение для отображения статистики">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,0.45)' }} />
                  </Tooltip>
                </Space>
                <Select
                  style={{ width: '100%', marginTop: 8 }}
                  value={selectedAppName}
                  onChange={(value) => handleAppChange(value, index)}
                  placeholder="Выберите приложение"
                  allowClear
                  showSearch
                  optionFilterProp="label"
                >
                  {appOptions.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            
            <Divider style={{ margin: '12px 0' }} />
            
            <div className="chart-container" style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {loading || isRefreshing ? (
                <Spin size="large" />
              ) : filteredStatistics ? (
                <PushPieChart pushCount={filteredStatistics.pushCount || 0} />
              ) : (
                <Empty 
                  description="Нет данных для отображения" 
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
            
            <Divider style={{ margin: '12px 0' }} />
            
            <div className="stats-container">
              {filteredStatistics ? (
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Statistic 
                      title="Пуши" 
                      value={filteredStatistics.pushCount || 0} 
                      prefix={<BellOutlined className="stat-icon" />}
                      className="statistic-pushes"
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic 
                      title="Регистрации" 
                      value={filteredStatistics.regCount || 0} 
                      prefix={<UserOutlined className="stat-icon" />}
                      className="statistic-regs"
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic 
                      title="Депозиты" 
                      value={filteredStatistics.depCount || 0} 
                      prefix={<MoneyIcon className="stat-icon" />}
                      className="statistic-deps"
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic 
                      title="Дата" 
                      value={filteredStatistics.sentAt || 'Н/Д'} 
                      prefix={<CalendarOutlined className="stat-icon" />}
                      valueStyle={{ fontSize: '14px' }}
                      className="statistic-date"
                    />
                  </Col>
                </Row>
              ) : (
                <Empty description="Нет статистики" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </Card>
        </Col>
      );
    });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar role={role} username={username} />
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Content style={{ margin: '24px 16px', overflow: 'initial' }}>
          <Routes>
            <Route 
              path="main" 
              element={
                <>
                  <div className="dashboard-header">
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Title level={2} style={{ margin: 10 }}>
                          <DashboardOutlined /> Основная панель
                        </Title>
                      </Col>
                      <Col>
                        <Space>
                          <Button 
                            type="primary" 
                            icon={<ReloadOutlined />} 
                            onClick={fetchAllData}
                            loading={loading}
                          >
                            Обновить все
                          </Button>
                          <div className="panel-selector">
                            <Text>Количество дашбордов:</Text>
                            <Select
                              value={panelCount.toString()}
                              onChange={handlePanelCountChange}
                              style={{ width: 80, marginLeft: 12 }}
                              dropdownMatchSelectWidth={false}
                            >
                              <Option value="2">2</Option>
                              <Option value="4">4</Option>
                              <Option value="6">6</Option>
                              <Option value="8">8</Option>
                            </Select>
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  
                  <Row gutter={[16, 16]}>
                    {renderPanels()}
                  </Row>
                </>
              } 
            />
            {(role === 'editor' || role === 'admin') && (
              <>
                <Route path="templates" element={<Templates />} />
                <Route path="conditions" element={<Conditions />} />
              </>
            )}
            <Route path="statistics" element={<Statistics />} />
            <Route path="statisticapp" element={<StatisticsApps />} />
            <Route path="yourtask" element={<TaskManager />} />
            <Route path="deepanalyz" element={<StatisticsComponent />} />
            <Route path="compare" element={<ConditionComparison />} />
            {role === 'admin' && (
              <>
                <Route path="users" element={<UsersManagement />} />
                <Route path="logs" element={<Logs />} />
                <Route path="apps" element={<Apps />} />
                <Route path="test" element={<TestPush />} />
              </>
            )}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

// Компоненты иконок
const NotificationIcon = () => (
  <span role="img" aria-label="notification" style={{ color: '#1890ff' }}>
    <svg viewBox="64 64 896 896" focusable="false" data-icon="notification" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.7c-3.7 11.6-5.7 23.9-5.7 36.4 0 65.9 53.8 119.5 120 119.5 55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3 16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112zM344 762.3c-26.5 0-48-21.4-48-47.8 0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1z"></path>
    </svg>
  </span>
);

const MoneyIcon = () => (
  <span role="img" aria-label="money" style={{ color: '#52c41a' }}>
    <svg viewBox="64 64 896 896" focusable="false" data-icon="money-collect" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M911.5 700.7a8 8 0 00-10.3-4.8L840 718.2V180c0-37.6-30.4-68-68-68H252c-37.6 0-68 30.4-68 68v538.2l-61.3-22.3c-.9-.3-1.8-.5-2.7-.5-4.4 0-8 3.6-8 8V763c0 3.3 2.1 6.3 5.3 7.5L501 909.1c7.1 2.6 14.8 2.6 21.9 0l383.8-139.5c3.2-1.2 5.3-4.2 5.3-7.5v-59.6c0-1-.2-1.9-.5-2.8zM512 837.5l-256-93.1V184h512v560.4l-256 93.1zM660.6 312h-54.5c-3 0-5.8 1.7-7.1 4.4l-84.7 168.8H511l-84.7-168.8a8 8 0 00-7.1-4.4h-55.7c-1.3 0-2.6.3-3.8 1-3.9 2.1-5.3 7-3.2 10.8l103.9 191.6h-57c-4.4 0-8 3.6-8 8v27.1c0 4.4 3.6 8 8 8h76v39h-76c-4.4 0-8 3.6-8 8v27.1c0 4.4 3.6 8 8 8h76V704c0 4.4 3.6 8 8 8h49.9c4.4 0 8-3.6 8-8v-63.5h76.3c4.4 0 8-3.6 8-8v-27.1c0-4.4-3.6-8-8-8h-76.3v-39h76.3c4.4 0 8-3.6 8-8v-27.1c0-4.4-3.6-8-8-8H564l103.7-191.6c.6-1.2 1-2.5 1-3.8-.1-4.3-3.7-7.9-8.1-7.9z"></path>
    </svg>
  </span>
);

export default Dashboard;