import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Ant Design компоненты
import {
  Card,
  Input,
  Button,
  Space,
  DatePicker
} from 'antd';

import '../styles/StatisticsApps.css'; // Подключаем внешний файл стилей

const StatisticsApps = () => {
  const [statistics, setStatistics] = useState([]);
  const [searchStatTerm, setSearchStatTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormVisible, setIsFormVisible] = useState(false);

  // Параметры фильтра
  const [geo, setGeo] = useState('');
  const [timeStart, setTimeStart] = useState(null); // теперь храним дату как объект (или строку)
  const [timeEnd, setTimeEnd] = useState(null);
  const [offer, setOffer] = useState('');

  // Данные о пользователе
  const team = localStorage.getItem('team');
  const role = localStorage.getItem('role');

  // Пагинация
  const itemsPerPage = 10;

  // Получаем статистику при первом рендере
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        // Изначально загрузим все данные
        const response = await axios.post('/api/statapp/statistics', {
          geo: "",
          timeStart: "",
          timeEnd: "",
          team,
          role,
          offer: ""
        });
        setStatistics(response.data);
      } catch (error) {
        console.error('Ошибка при получении статистики:', error);
      }
    };

    fetchStatistics();
  }, [team, role]);

  // Запрос для сброса (глобального получения)
  const fetchStatistics = async () => {
    try {
      const response = await axios.get('/api/statapp/statistics');
      setStatistics(response.data);
    } catch (error) {
      console.error('Ошибка при получении статистики:', error);
    }
  };

  // Фильтрация по названию приложения (локально)
  const filteredStat = statistics
    .filter((item) =>
      item.appName.toLowerCase().includes(searchStatTerm.toLowerCase())
    );

  // Высчитываем нужные данные для отображения текущей страницы
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredStat.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredStat.length / itemsPerPage);

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Отправка формы фильтра
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // DatePicker в AntD обычно возвращает объект даты (dayjs),
      // поэтому если бэкенд ожидает строку, нужно взять dateString.
      const response = await axios.post('/api/statapp/statistics', {
        geo,
        timeStart: timeStart ? timeStart.format('YYYY-MM-DD') : "",
        timeEnd: timeEnd ? timeEnd.format('YYYY-MM-DD') : "",
        team,
        role,
        offer
      });

      setStatistics(response.data);
      setIsFormVisible(false);
    } catch (error) {
      console.error('Ошибка при сохранении данных:', error);
    }
  };

  // Сброс фильтров
  const handleCancel = async (e) => {
    e.preventDefault();
    try {
      // Возвращаемся к исходным данным
      fetchStatistics();
      // Сбрасываем поля
      setGeo('');
      setTimeStart(null);
      setTimeEnd(null);
      setOffer('');
    } catch (error) {
      console.error('Ошибка при сбросе фильтра:', error);
    }
  };

  // Экспорт в CSV
  const exportToCSV = (filteredData, filename = 'filteredStat.csv') => {
    if (!filteredData.length) {
      console.log('No data to export.');
      return;
    }

    // Пример удаления ключа AppTeam (или что вам не нужно)
    const cleanedData = filteredData.map((item) => {
      const { AppTeam, ...rest } = item;
      return rest;
    });

    // Заголовки
    const headers = Object.keys(cleanedData[0]).join(',');

    // Преобразуем данные в CSV
    const rows = cleanedData.map((item) =>
      Object.values(item)
        .map((value) =>
          value === undefined || value === null || value === '' ? 'нет' : value
        )
        .map((value) => `"${value}"`)
        .join(',')
    );

    const csvContent = [headers, ...rows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="statisticsContainer">
      {/* Карточка заголовка и поиска */}
      <Card
        title="Статистика"
        style={{ marginBottom: 16 }}
      >
        <Space style={{ marginBottom: 16 }}>
          {/* Поиск */}
          <Input
            placeholder="Поиск по названию приложения"
            value={searchStatTerm}
            onChange={(e) => setSearchStatTerm(e.target.value)}
            style={{ width: 300 }}
          />
          <Button onClick={() => setIsFormVisible(!isFormVisible)}>
            {isFormVisible ? 'Свернуть форму' : 'Фильтр'}
          </Button>
          <Button onClick={() => exportToCSV(filteredStat)}>
            Скачать CSV
          </Button>
        </Space>

        {/* Форма фильтра (Гео, даты, Offer) */}
        {isFormVisible && (
          <form onSubmit={handleSubmit} className="filterForm">
            <div className="formRow">
              <label className="formLabel">Гео:</label>
              <Input
                type="text"
                value={geo}
                onChange={(e) => setGeo(e.target.value)}
                style={{ width: 200 }}
              />
            </div>

            <div className="formRow">
              <label className="formLabel">Дата начала:</label>
              {/* AntD DatePicker */}
              <DatePicker
                value={timeStart}
                onChange={(date) => setTimeStart(date)}
                style={{ width: 200 }}
                placeholder="Выберите дату начала"
              />
            </div>

            <div className="formRow">
              <label className="formLabel">Дата конца:</label>
              {/* AntD DatePicker */}
              <DatePicker
                value={timeEnd}
                onChange={(date) => setTimeEnd(date)}
                style={{ width: 200 }}
                placeholder="Выберите дату конца"
              />
            </div>

            <div className="formRow">
              <label className="formLabel">Offer:</label>
              <Input
                type="text"
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
                style={{ width: 200 }}
              />
            </div>

            <Space>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
              <Button onClick={handleCancel} danger>
                Сбросить фильтр
              </Button>
            </Space>
          </form>
        )}
      </Card>

      {/* Карточка со списком результатов */}
      <Card>
        {statistics.length > 0 ? (
          <div>
            {/* Выводим текущую страницу данных */}
            <ul className="statList">
              {currentPageData.map((group, index) => (
                <li
                  key={group._id || index}
                  className="statListItem"
                >
                  <Button
                    type="link"
                    onClick={() => {
                      const panel = document.getElementById(`panel-${group._id || index}`);
                      if (panel) {
                        panel.style.display = panel.style.display === "none" ? "block" : "none";
                      }
                    }}
                    style={{  textAlign: 'left', padding: 0, margin: 0 }}
                  >
                    {`Приложение: ${group.appName}`}
                  </Button>
                  <div
                    id={`panel-${group._id || index}`}
                    className="hidden"
                    style={{ marginTop: '10px' }}
                  >
                    <p>Количество пушей: {group.pushCount}</p>
                    <p>Дата отправки: {group.sentAt}</p>
                    <p>Кол-во рег: {group.regCount}</p>
                    <p>Кол-во депов: {group.depCount}</p>
                  </div>
                </li>
              ))}
            </ul>

            {/* Пагинация */}
            <Space style={{ marginTop: 16 }}>
              <Button
                onClick={() => changePage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Назад
              </Button>
              <span>
                Страница {currentPage} из {totalPages}
              </span>
              <Button
                onClick={() => changePage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Вперед
              </Button>
            </Space>
          </div>
        ) : (
          <p>Нет данных для отображения.</p>
        )}
      </Card>
    </div>
  );
};

export default StatisticsApps;
