import React from 'react';
import { Button, Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../styles/CustomePagitation.css';

const CustomPagination = ({ 
  total, 
  current, 
  pageSize, 
  onChange, 
  onPageSizeChange 
}) => {
  // Вычисляем общее количество страниц
  const totalPages = Math.ceil(total / pageSize);
  
  // Функция для создания массива страниц
  const getPageNumbers = () => {
    const pages = [];
    // Показываем максимум 5 страниц
    if (totalPages <= 5) {
      // Если всего страниц 5 или меньше, показываем все
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Если страниц больше 5, показываем текущую с соседями
      if (current <= 3) {
        // Если текущая страница ближе к началу
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (current >= totalPages - 2) {
        // Если текущая страница ближе к концу
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        // Текущая страница в середине
        pages.push(1, '...', current - 1, current, current + 1, '...', totalPages);
      }
    }
    return pages;
  };

  // Обработчики событий
  const handlePrevClick = () => {
    if (current > 1) {
      onChange(current - 1);
    }
  };

  const handleNextClick = () => {
    if (current < totalPages) {
      onChange(current + 1);
    }
  };

  const handlePageClick = (page) => {
    if (page !== '...' && page !== current) {
      onChange(page);
    }
  };

  return (
    <div className="footer-pagination-container">
      <div className="page-info">
        Всего: {total} приложений
      </div>
      
      <ul className="pagination-list">
        <li className="pagination-item">
          <Button 
            className="nav-button" 
            disabled={current === 1}
            onClick={handlePrevClick}
            icon={<LeftOutlined />}
            size="small"
            type="default"
            style={{ background: 'black', borderColor: '#d9d9d9' }}
          />
        </li>
        
        {getPageNumbers().map((page, index) => (
          <li key={index} className="pagination-item">
            {page === '...' ? (
              <span className="pagination-ellipsis">...</span>
            ) : (
              <Button
                className={page === current ? 'pagination-active' : ''}
                type={page === current ? 'primary' : 'default'}
                onClick={() => handlePageClick(page)}
                size="small"
                style={{
                  backgroundColor: page === current ? '#e74c3c' : 'white',
                  borderColor: page === current ? '#e74c3c' : '#d9d9d9',
                  minWidth: '32px'
                }}
              >
                {page}
              </Button>
            )}
          </li>
        ))}
        
        <li className="pagination-item">
          <Button 
            className="nav-button" 
            disabled={current === totalPages || totalPages === 0}
            onClick={handleNextClick}
            icon={<RightOutlined />}
            size="small"
            type="default"
            style={{ background: 'white', borderColor: '#d9d9d9' }}
          />
        </li>
      </ul>
      
      <div className="page-size-selector">
        <span>Строк на странице:</span>
        <Select
          value={pageSize}
          onChange={onPageSizeChange}
          style={{ width: 80, marginLeft: 8 }}
          options={[
            { value: 8, label: '8' },
            { value: 16, label: '16' },
            { value: 24, label: '24' },
          ]}
        />
      </div>
    </div>
  );
};

export default CustomPagination;