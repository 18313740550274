import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Card, 
  Typography, 
  Tabs, 
  List, 
  Tag, 
  Space, 
  Spin, 
  Empty, 
  Button, 
  Collapse, 
  Tooltip, 
  Badge,
  Alert,
  Divider,
  Row,
  Col
} from 'antd';
import { 
  FileSearchOutlined, 
  WarningOutlined, 
  InfoCircleOutlined, 
  ExclamationCircleOutlined, 
  UserOutlined, 
  ClockCircleOutlined, 
  SyncOutlined,
  CaretRightOutlined
} from '@ant-design/icons';
import '../styles/Logs.css';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedLogId, setExpandedLogId] = useState(null);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/logs');
      setLogs(res.data);
      setError(null);
    } catch (err) {
      console.error('Ошибка при загрузке логов', err);
      setError('Не удалось загрузить логи системы. Пожалуйста, попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  const toggleLogDetails = (logId) => {
    setExpandedLogId(expandedLogId === logId ? null : logId);
  };

  const groupedLogs = logs.reduce((acc, log) => {
    (acc[log.level] = acc[log.level] || []).push(log);
    return acc;
  }, {});

  const getLevelIcon = (level) => {
    switch (level) {
      case 'error':
        return <ExclamationCircleOutlined style={{ color: '#f5222d' }} />;
      case 'warn':
        return <WarningOutlined style={{ color: '#faad14' }} />;
      case 'info':
        return <InfoCircleOutlined style={{ color: '#1890ff' }} />;
      default:
        return <InfoCircleOutlined />;
    }
  };

  const getLevelTag = (level) => {
    switch (level) {
      case 'error':
        return <Tag color="error">ERROR</Tag>;
      case 'warn':
        return <Tag color="warning">WARNING</Tag>;
      case 'info':
        return <Tag color="processing">INFO</Tag>;
      default:
        return <Tag>UNKNOWN</Tag>;
    }
  };

  const getLevelCounts = () => {
    return {
      error: groupedLogs.error?.length || 0,
      warn: groupedLogs.warn?.length || 0,
      info: groupedLogs.info?.length || 0
    };
  };

  const counts = getLevelCounts();

  return (
    <Row justify="center" style={{ marginTop: 24 }}>
      <Col xs={24} sm={22} md={20} lg={18} xl={16}>
        <Card 
          title={
            <Space align="center">
              <FileSearchOutlined />
              <Title level={3} style={{ margin: 0 }}>Логи системы</Title>
            </Space>
          }
          extra={
            <Tooltip title="Обновить">
              <Button 
                icon={<SyncOutlined spin={loading} />} 
                onClick={fetchLogs} 
                loading={loading}
              >
                Обновить
              </Button>
            </Tooltip>
          }
          style={{ width: '100%' }}
        >
          {error && (
            <Alert 
              message="Ошибка" 
              description={error} 
              type="error" 
              showIcon 
              style={{ marginBottom: 16 }} 
            />
          )}

          <Tabs defaultActiveKey="all">
            <TabPane 
              tab={
                <span>
                  <FileSearchOutlined />
                  Все логи
                  <Badge 
                    count={counts.error + counts.warn + counts.info} 
                    style={{ marginLeft: 8 }} 
                    size="small" 
                  />
                </span>
              } 
              key="all"
            >
              {loading ? (
                <div className="loading-container">
                  <Spin tip="Загрузка логов..." />
                </div>
              ) : logs.length === 0 ? (
                <Empty description="Логи не найдены" />
              ) : (
                <Collapse 
                  bordered={false} 
                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                >
                  {['error', 'warn', 'info'].map(level => (
                    <Panel 
                      header={
                        <Space>
                          {getLevelIcon(level)}
                          <Text strong>{level.toUpperCase()}</Text>
                          <Badge count={counts[level]} />
                        </Space>
                      }
                      key={level}
                    >
                      <List
                        itemLayout="vertical"
                        dataSource={groupedLogs[level] || []}
                        renderItem={log => (
                          <List.Item
                            key={log._id}
                            className={`log-item log-${log.level}`}
                            actions={[
                              <Space>
                                <ClockCircleOutlined />
                                <span>{log.timestamp}</span>
                              </Space>,
                              <Space>
                                <UserOutlined />
                                <span>{log.user?.username || 'Система'}</span>
                              </Space>
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <Space>
                                  {getLevelTag(log.level)}
                                  <Text strong>{log.action}</Text>
                                </Space>
                              }
                              description={
                                <div className="log-message">
                                  <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'Подробнее' }}>
                                    {log.message}
                                  </Paragraph>
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Panel>
                  ))}
                </Collapse>
              )}
            </TabPane>
            <TabPane 
              tab={
                <span>
                  <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
                  Ошибки
                  <Badge count={counts.error} style={{ marginLeft: 8 }} size="small" color="red" />
                </span>
              } 
              key="error"
            >
              {loading ? (
                <div className="loading-container">
                  <Spin tip="Загрузка логов..." />
                </div>
              ) : !groupedLogs.error || groupedLogs.error.length === 0 ? (
                <Empty description="Ошибки не найдены" />
              ) : (
                <List
                  itemLayout="vertical"
                  dataSource={groupedLogs.error || []}
                  renderItem={log => (
                    <List.Item
                      key={log._id}
                      className="log-item log-error"
                      actions={[
                        <Space>
                          <ClockCircleOutlined />
                          <span>{log.timestamp}</span>
                        </Space>,
                        <Space>
                          <UserOutlined />
                          <span>{log.user?.username || 'Система'}</span>
                        </Space>
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          <Space>
                            <Tag color="error">ERROR</Tag>
                            <Text strong>{log.action}</Text>
                          </Space>
                        }
                        description={
                          <div className="log-message">
                            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'Подробнее' }}>
                              {log.message}
                            </Paragraph>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </TabPane>
            <TabPane 
              tab={
                <span>
                  <WarningOutlined style={{ color: '#faad14' }} />
                  Предупреждения
                  <Badge count={counts.warn} style={{ marginLeft: 8 }} size="small" color="orange" />
                </span>
              } 
              key="warn"
            >
              {loading ? (
                <div className="loading-container">
                  <Spin tip="Загрузка логов..." />
                </div>
              ) : !groupedLogs.warn || groupedLogs.warn.length === 0 ? (
                <Empty description="Предупреждения не найдены" />
              ) : (
                <List
                  itemLayout="vertical"
                  dataSource={groupedLogs.warn || []}
                  renderItem={log => (
                    <List.Item key={log._id} className="log-item log-warn">
                      <List.Item.Meta
                        title={
                          <Space>
                            <Tag color="warning">WARNING</Tag>
                            <Text strong>{log.action}</Text>
                          </Space>
                        }
                        description={log.message}
                      />
                      <div className="log-details">
                        <Space>
                          <ClockCircleOutlined />
                          <span>{log.timestamp}</span>
                        </Space>
                        <Divider type="vertical" />
                        <Space>
                          <UserOutlined />
                          <span>{log.user?.username || 'Система'}</span>
                        </Space>
                      </div>
                    </List.Item>
                  )}
                />
              )}
            </TabPane>
            <TabPane 
              tab={
                <span>
                  <InfoCircleOutlined style={{ color: '#1890ff' }} />
                  Информация
                  <Badge count={counts.info} style={{ marginLeft: 8 }} size="small" />
                </span>
              } 
              key="info"
            >
              {loading ? (
                <div className="loading-container">
                  <Spin tip="Загрузка логов..." />
                </div>
              ) : !groupedLogs.info || groupedLogs.info.length === 0 ? (
                <Empty description="Информационные логи не найдены" />
              ) : (
                <List
                  itemLayout="vertical"
                  dataSource={groupedLogs.info || []}
                  renderItem={log => (
                    <List.Item key={log._id} className="log-item log-info">
                      <List.Item.Meta
                        title={
                          <Space>
                            <Tag color="processing">INFO</Tag>
                            <Text strong>{log.action}</Text>
                          </Space>
                        }
                        description={log.message}
                      />
                      <div className="log-details">
                        <Space>
                          <ClockCircleOutlined />
                          <span>{log.timestamp}</span>
                        </Space>
                        <Divider type="vertical" />
                        <Space>
                          <UserOutlined />
                          <span>{log.user?.username || 'Система'}</span>
                        </Space>
                      </div>
                    </List.Item>
                  )}
                />
              )}
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default Logs;