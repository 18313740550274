import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import Sidebar from '../components/Sidebar';
import {jwtDecode} from 'jwt-decode';




const Login = () => {
  const [usernameInput, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleLogin = () => {
        window.location.href = 'https://api.pnsynd.com/auth/auth/google';

  }
  const goToDash = () => {
    navigate('/dashboard/main');
  }
  const [refresh, setRefresh] = useState(false);

  // Функция для вызова обновления
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { username: usernameInput, password });
      localStorage.setItem('token', response.data.token); // Сохраняем токен
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      localStorage.setItem('username', usernameInput);
      localStorage.setItem('role', decodedToken.role);
      localStorage.setItem('team', decodedToken.team)
      localStorage.setItem('userID', decodedToken.id)
      localStorage.setItem('google', 'no')
      console.log(decodedToken.id) // Сохраняем username
      navigate('/dashboard/main');
      Sidebar.forceUpdate();
      Login.forceUpdate();
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
        try {
            const response = await axios.get('https://api.pnsynd.com/auth/auth/me', {
                withCredentials: true,
            });
            console.log(response.data)
            localStorage.setItem('token', response.data); // Сохраняем токен
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            console.log("Full Decoded Token:", JSON.stringify(decodedToken, null, 2) + "  " + token)
            localStorage.setItem('username', decodedToken.name);
            localStorage.setItem('role', decodedToken.role);
            localStorage.setItem('team', decodedToken.team)
            localStorage.setItem('userID', decodedToken.id)
  
        } catch (error) {
            console.error('Ошибка проверки авторизации:', error);
        
        }
    };
    checkAuth();
  
}, []);


  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Вход</h2>
        <div className="input-group">
          <input
            
            placeholder="Логин"
            value={usernameInput}
            onChange={(e) => setUsernameInput(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-button">Login</button>
          <button type="button" style={{"backgroundColor":"#1abc9c","margin":"0"}} onClick={handleLogin}> Проверка Google Account</button>
          <button type="button" style={{"backgroundColor":"#1abc9c","margin":"0"}} onClick={goToDash}> Войти через Google</button>
        </div>
        {username && <p className="error-message">{username}</p>}
      </form>
    </div>
  );
};

export default Login;
// <button type="button" style={{"backgroundColor":"#1abc9c","margin":"0"}} onClick={handleLogin}> Войти через Google</button>





