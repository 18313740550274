import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Form, 
  Input, 
  Button, 
  Card, 
  Typography, 
  Divider, 
  Alert, 
  Space, 
  Spin,
  ConfigProvider
} from 'antd';
import { 
  UserOutlined, 
  LockOutlined, 
  GoogleOutlined, 
  LoginOutlined
} from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';
import '../styles/Login.css';

const { Title, Text } = Typography;

const Login = () => {
  const [usernameInput, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    setGoogleLoading(true);
    window.location.href = 'https://api.pnsynd.com/auth/auth/google';
  };

  const goToDash = () => {
    navigate('/dashboard/main');
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      setError('');
      
      const response = await axios.post('/api/auth/login', { 
        username: values.username, 
        password: values.password 
      });
      
      localStorage.setItem('token', response.data.token);
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      
      localStorage.setItem('username', values.username);
      localStorage.setItem('role', decodedToken.role);
      localStorage.setItem('team', decodedToken.team);
      localStorage.setItem('userID', decodedToken.id);
      localStorage.setItem('google', 'no');
      
      navigate('/dashboard/main');
    } catch (err) {
      setError(err.response?.data?.message || 'Ошибка входа. Проверьте логин и пароль.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setCheckingAuth(true);
        const response = await axios.get('https://api.pnsynd.com/auth/auth/me', {
          withCredentials: true,
        });
        
        localStorage.setItem('token', response.data);
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        
        localStorage.setItem('username', decodedToken.name);
        localStorage.setItem('role', decodedToken.role);
        localStorage.setItem('team', decodedToken.team);
        localStorage.setItem('userID', decodedToken.id);
        localStorage.setItem('google', 'yes');
        
        // Если успешно аутентифицированы, перенаправляем на дашборд
        if (token) {
          navigate('/dashboard/main');
        }
      } catch (error) {
        console.error('Ошибка проверки авторизации:', error);
      } finally {
        setCheckingAuth(false);
      }
    };
    
    checkAuth();
  }, [navigate]);

  if (checkingAuth) {
    return (
      <div className="login-loading-container">
        <Spin size="large" tip="Проверка авторизации..." />
      </div>
    );
  }

  return (
    <ConfigProvider componentSize="large">
      <div className="login-container">
        <Card 
          className="login-card"
          title={
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Title level={2} style={{ margin: 0 }}>
                Вход в систему
              </Title>
            </div>
          }
          bordered={false}
          style={{ width: '100%', maxWidth: '420px' }}
        >
          {error && (
            <Alert 
              message="Ошибка" 
              description={error} 
              type="error" 
              showIcon 
              style={{ marginBottom: 24, width: '100%' }} 
              closable
            />
          )}
          
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}
            style={{ width: '100%' }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Пожалуйста, введите ваш логин' }]}
            >
              <Input 
                prefix={<UserOutlined />} 
                placeholder="Логин" 
                size="large"
                onChange={(e) => setUsernameInput(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Пароль"
                size="large"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                block 
                size="large"
                icon={<LoginOutlined />}
                loading={loading}
              >
                Войти
              </Button>
            </Form.Item>
          </Form>
          
          <Divider style={{ width: '100%' }}>
            <Text type="secondary">или</Text>
          </Divider>
          
          <Space direction="vertical" style={{ width: '100%' }}>
            <Button 
              type="default" 
              block 
              size="large" 
              icon={<GoogleOutlined />} 
              onClick={handleGoogleLogin}
              loading={googleLoading}
            >
              Войти через Google
            </Button>
            
            <Button direction="vertical" style={{ width: '100%' }}
              type="link" 
              block 
              onClick={goToDash}
            >
              Войти в дашборд
            </Button>
          </Space>
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default Login;