import { configureStore } from '@reduxjs/toolkit';
// Импортируйте ваши reducers

const store = configureStore({
  reducer: {
    // Добавьте ваши reducers здесь
  }
});

export default store;
