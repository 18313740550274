import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Templates from './Templates';
import Conditions from './Conditions';
import UsersManagement from './UsersManagement';
import Statistics from './Statistics';
import Logs from './Logs';
import Apps from './Apps';
import '../styles/Dashboard.css';
import TestPush from './TestPush';
import axios from 'axios';
import PushPieChart from './PushPieChart'; 
import Select from 'react-select'
import StatisticsApps from './StatisticsApps'
import TaskManager from './Task'
import StatisticsComponent from './Analytic'
import ConditionComparison from './Compare'

const Dashboard = ({ role, username }) => {
  const [panelCount, setPanelCount] = useState(2);
  const [conditions, setConditions] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedApps, setSelectedApps] = useState({});
  const [statistics, setStatistics] = useState([]);
  const [appStatistics, setAppStatistics] = useState([]);
  const location = useLocation();
  const team = localStorage.getItem('team');
  const [searchTerm, setSearchTerm] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(true);  // Меняем значение по умолчанию на true
  const [openIndex, setOpenIndex] = useState(null);
  const [openMenus, setOpenMenus] = useState({ condition: null, app: null });





  const options = conditions.map(condition => ({
    value: condition._id,
    label: condition.name
  }));
  const optionsApps = apps.map(app => ({
    value: app.appName,
    label: app.appName
  }));

  options.unshift({ value: '', label: 'Выбор условия' });
  optionsApps.unshift({ value: '', label: 'Выбор приложения' });
  useEffect(() => {
    // Загружаем список условий
    const fetchConditions = async (appName) => {
      try {
        const response = await axios.post('/api/get-conditions', { appName,team, role });
        setConditions(response.data)
        //console.log(response.data)
      } catch (error) {
        console.error('Ошибка при получении условий:', error);
      }
      
    };

    const fetchApps = async () => {
      try {
        const response = await axios.post('/api/statapp/statistics', { geo: "", timeStart: "", timeEnd: "", team, role, offer: "", team, role });
        setApps(response.data);
      } catch (error) {
        console.error('Ошибка при получении условий:', error);
      }
    };

    // Загружаем все данные статистики
    const fetchStatistics = async () => {
      try {
        const response = await axios.post('/api/statistics', { appName: "", geo: "", timeStart: "", timeEnd: "", team, role, offer: "" });
        setStatistics(response.data);
      } catch (error) {
        console.error('Ошибка при получении статистики:', error);
      }
    };

    const fetchAppStatistics = async () => {
      try {
        const response = await axios.post('/api/statapp/statistics', {  geo: "", timeStart: "", timeEnd: "", team, role, offer: "" });
        setAppStatistics(response.data);
        console.log(appStatistics)
      } catch (error) {
        console.error('Ошибка при получении статистики:', error);
      }
    };

    // Чтение сохранённых данных из localStorage при загрузке
    const savedSelectedConditions = JSON.parse(localStorage.getItem('selectedConditions')) || {};
    setSelectedConditions(savedSelectedConditions);

    const savedSelectedApps = JSON.parse(localStorage.getItem('selectedApps')) || {};
    setSelectedApps(savedSelectedApps);

    fetchConditions();
    fetchStatistics();
    fetchApps();
    fetchAppStatistics();
  }, []);

  // Сохраняем выбранные условия в localStorage при каждом изменении
  useEffect(() => {
    localStorage.setItem('selectedConditions', JSON.stringify(selectedConditions));
  }, [selectedConditions]);
  useEffect(() => {
  localStorage.setItem('selectedApps', JSON.stringify(selectedApps));
  }, [selectedApps]);


  

  const handlePanelCountChange = (event) => {
    setPanelCount(Number(event.target.value));
  };

  const fetchConditions = async (appName) => {
    try {
      const response = await axios.post('/api/get-conditions', { team, role });
      
      
      let conds = response.data
      if (!!appName) {
      conds = conds.filter(cond => cond.appId.name === appName);
      }
      setConditions(conds);
    } catch (error) {
      console.error('Ошибка при получении условий:', error);
    }
  };

  const filteredConditions = conditions.filter(condition =>
    condition.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleConditionChange = (index, event) => {
    const selectedConditionId = event.target.value;
    setSelectedConditions(prev => ({ ...prev, [index]: selectedConditionId }));
  };

  const handleAppChange = (index, event) => {
    const selectedAppName = event.target.value;
    fetchConditions(selectedAppName);
    setSelectedApps(prev => ({ ...prev, [index]: selectedAppName }));
    };

  const handleSelectChange = (selectedOption, index) => {
    handleConditionChange(index, { target: { value: selectedOption.value } });
    setOpenIndex(null); // Закрытие меню после выбора
  };

  const handleSelectChangeApps = (selectedOption, index) => {
    handleAppChange(index, { target: { value: selectedOption.value } });
    setOpenIndex(null); // Закрытие меню после выбора
  };

  // Функция открытия меню
  const handleMenuOpen = (type, index) => {
    setOpenMenus(prev => ({ ...prev, [type]: index }));
  };

  // Функция закрытия меню
  const handleMenuClose = (type) => {
    setOpenMenus(prev => ({ ...prev, [type]: null }));
  };

  const getGridTemplateColumns = () => {
    switch(panelCount) {
      case 2:
      case 4:
        return '1fr';
      case 6:
        return '1fr';
      case 8:
        return '1fr';
      default:
        return '1fr';
    }
  };

  const panels = Array.from({ length: panelCount }, (_, index) => {
    const selectedConditionId = selectedConditions[index];
    const selectedAppName = selectedApps[index];
    const AppName = apps.find(app => app.appName === selectedAppName)?.appName;
    const conditionName = conditions.find(cond => cond._id === selectedConditionId)?.name;
    let filteredStatistics
    if (!!AppName & !!conditionName) {
      filteredStatistics = statistics.find(stat => stat.conditionName === conditionName);
    }
    else if (!!AppName) {
        filteredStatistics = appStatistics.find(stat => stat.appName === AppName); }
    else if (!!conditionName)
        filteredStatistics = statistics.find(stat => stat.conditionName === conditionName);  // Находим статистику по названию условия

    const customStyles = {
      control: (provided) => ({
        ...provided,
        width: '300px', // Фиксированная ширина поля ввода
        minWidth: '200px', // Минимальная ширина, чтобы не сжималась
      }),
      input: (provided) => ({
        ...provided,
        minWidth: '100px', // Минимальная ширина ввода
      }),
    };

    return (
      <div key={index} className="info-panel">
        {/* Шапка с выбором условия */}
        <div className="panel-header">
          <label className="statP" htmlFor={`condition-select-${index}`}>Выбор статистики:</label>
          <Select
            id={`condition-select-${index}`}
            value={options.find(option => option.value === selectedConditionId) || null}
            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
            options={options}
            placeholder="Выберите условие"
            menuIsOpen={openMenus.condition === index} // Открытие только для нужного индекса
            onMenuClose={() =>handleMenuClose('condition')} // Закрытие меню при потере фокуса
            onMenuOpen={() => handleMenuOpen('condition',index)} // Открытие меню для текущего компонента
            styles={customStyles}
          />
          <label className="statP" htmlFor={`app-select-${index}`}>Выбор приложения:</label>
          <Select
            id={`app-select-${index}`}
            value={optionsApps.find(optionsApps => optionsApps.value === selectedAppName) || null}
            onChange={(selectedOption) => handleSelectChangeApps(selectedOption, index)}
            options={optionsApps}
            placeholder="Выбор приложения:"
            menuIsOpen={openMenus.app === index} // Открытие только для нужного индекса
            onMenuClose={() =>handleMenuClose('app')} // Закрытие меню при потере фокуса
            onMenuOpen={() => handleMenuOpen('app',index)} // Открытие меню для текущего компонента
            styles={customStyles}
          />
        </div>

        {/* Тело панели с диаграммой */}
        <div style={{display:"flex" , "flexDirection": "row"}}className="panel-body">
          {filteredStatistics ? (
            <PushPieChart pushCount={filteredStatistics.pushCount || 0} />  // Передаем количество пушей в диаграмму
          ) : (
            <p style={{color:"white"}} >Нет данных для диаграммы</p>
          )}
        </div>

        {/* Футер с отображением статистики */}
        <div className="panel-footer">
          {filteredStatistics ? (
            <div style={{display: "flex"}}>
              <p className = "statP" >Количество пушей: {filteredStatistics.pushCount || 'Нет данных'}</p>
              <p className = "statP" >Дата отправки: {filteredStatistics.sentAt}</p>
              <p className = "statP" >Кол-во депов: {filteredStatistics.depCount}</p>
              <p className = "statP" >Кол-во регистраций: {filteredStatistics.regCount}</p>
            </div>
          ) : (
            <p style={{color:"white"}}>Нет статистики для выбранного условия</p>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="dashboard-container">
      <Sidebar role={role} username={username} />
      <div className="main-content">
        <Routes>
          <Route 
            path="main" 
            element={
              <>
                <div className="panel-controls">
                  <label htmlFor="panel-count">Выбор кол-ва дэшбордов:</label>
                  <select id="panel-count" value={panelCount} onChange={handlePanelCountChange}>
                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                  </select>
                </div>
                <div className="panels-container" style={{ gridTemplateColumns: getGridTemplateColumns() }}>
                  {panels}
                </div>
                
              </>
            } 
          />
          {role === 'editor' || role === 'admin' ? (
            <>
              <Route path="templates" element={<Templates />} />
              <Route path="conditions" element={<Conditions />} />
            </>
          ) : null}
          <Route path="statistics" element={<Statistics />} />
          <Route path="statisticapp" element={<StatisticsApps />} />
          <Route path="yourtask" element={<TaskManager />} />
          <Route path="deepanalyz" element={<StatisticsComponent />} />
          <Route path="compare" element={<ConditionComparison />} />
          {role === 'admin' ? (
            <>
              <Route path="users" element={<UsersManagement />} />
              <Route path="logs" element={<Logs />} />
              <Route path="apps" element={<Apps />} />
              <Route path="test" element={<TestPush />} />
            </>
          ) : null}
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;