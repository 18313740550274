import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Input,
  Button,
  Space,
  Typography,
  Row,
  Col,
  Divider,
  Form,
  message,
  Empty,
  Spin,
  Badge,
  Tooltip,
  Modal,
  ConfigProvider
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  UndoOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import '../styles/TaskAntd.css';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { confirm } = Modal;

// Кастомная тема для соответствия дизайну приложения
const customTheme = {
  token: {
    colorPrimary: '#1abc9c',
    colorError: '#e74c3c',
    borderRadius: 5,
  }
};

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ name: '', text: '' });
  const [editingTask, setEditingTask] = useState(null);
  const [editValues, setEditValues] = useState({ name: '', text: '' });
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const role = localStorage.getItem('role');
  const userId = localStorage.getItem('userID');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/task', {
        params: { userID: userId, role }
      });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks', error);
      message.error('Не удалось загрузить задачи');
    } finally {
      setLoading(false);
    }
  };

  const createTask = async () => {
    try {
      if (!newTask.name.trim() || !newTask.text.trim()) {
        message.warning('Пожалуйста, заполните название и текст задачи');
        return;
      }

      const response = await axios.post('/api/task', {
        name: newTask.name,
        text: newTask.text,
        userID: userId
      });
      
      setTasks(prev => [...prev, response.data.task]);
      setNewTask({ name: '', text: '' });
      form.resetFields();
      message.success('Задача успешно создана');
    } catch (error) {
      console.error('Error creating task', error);
      message.error('Ошибка при создании задачи');
    }
  };

  const updateTask = async (taskId, updates) => {
    try {
      // Если обновляем текст или название, проверяем что они не пусты
      if ((updates.name !== undefined && !updates.name.trim()) || 
          (updates.text !== undefined && !updates.text.trim())) {
        message.warning('Поля не могут быть пустыми');
        return;
      }

      const response = await axios.put(`/api/task/${taskId}`, {
        ...updates,
        role
      });

      // Обновляем локальное состояние задач
      setTasks(prev =>
        prev.map(task =>
          task._id === taskId ? { ...task, ...response.data.task } : task
        )
      );

      // Закрываем режим редактирования
      setEditingTask(null);
      message.success('Задача успешно обновлена');
    } catch (error) {
      console.error('Error updating task', error);
      message.error('Ошибка при обновлении задачи');
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await axios.delete(`/api/task/${taskId}`, { data: { role } });
      setTasks(prev => prev.filter(task => task._id !== taskId));
      message.success('Задача успешно удалена');
    } catch (error) {
      console.error('Error deleting task', error);
      message.error('Ошибка при удалении задачи');
    }
  };

  // Функция подтверждения удаления
  const showDeleteConfirm = (taskId, taskName) => {
    confirm({
      title: 'Вы уверены, что хотите удалить эту задачу?',
      content: `Задача: ${taskName}`,
      icon: <ExclamationCircleOutlined style={{ color: '#e74c3c' }} />,
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        deleteTask(taskId);
      }
    });
  };

  // Функция подтверждения изменения статуса
  const showStatusChangeConfirm = (taskId, taskName, newStatus) => {
    confirm({
      title: `Вы уверены, что хотите отметить задачу как ${newStatus ? 'завершенную' : 'незавершенную'}?`,
      content: `Задача: ${taskName}`,
      icon: <ExclamationCircleOutlined style={{ color: '#1abc9c' }} />,
      okText: 'Да',
      okType: 'primary',
      cancelText: 'Нет',
      onOk() {
        updateTask(taskId, { complete: newStatus });
      }
    });
  };

  const renderTask = (task, completed) => {
    if (editingTask === task._id) {
      return (
        <Card 
          key={task._id} 
          className="task-card editing" 
          bordered={true}
          style={{ marginBottom: 16 }}
        >
          <Form layout="vertical">
            <Form.Item 
              label="Название задачи" 
              rules={[{ required: true, message: 'Введите название задачи' }]}
            >
              <Input
                value={editValues.name}
                onChange={e => setEditValues({ ...editValues, name: e.target.value })}
                placeholder="Название задачи"
              />
            </Form.Item>
            <Form.Item 
              label="Описание задачи"
              rules={[{ required: true, message: 'Введите описание задачи' }]}
            >
              <TextArea
                value={editValues.text}
                onChange={e => setEditValues({ ...editValues, text: e.target.value })}
                placeholder="Описание задачи"
                rows={4}
              />
            </Form.Item>
            <Space>
              <Button 
                type="primary" 
                onClick={() => updateTask(task._id, { name: editValues.name, text: editValues.text })}
              >
                Сохранить
              </Button>
              <Button onClick={() => setEditingTask(null)}>Отмена</Button>
            </Space>
          </Form>
        </Card>
      );
    }

    return (
      <Badge.Ribbon 
        text={completed ? "Завершено" : "Активно"} 
        color={completed ? "green" : "blue"}
        key={task._id}
      >
        <Card
          className={`task-card ${completed ? 'completed' : ''}`}
          style={{ marginBottom: 16 }}
          actions={[
            // Кнопки действий в зависимости от роли и статуса
            ...(!completed && role !== 'admin' ? [
              <Tooltip title="Редактировать">
                <Button 
                  type="text" 
                  icon={<EditOutlined />} 
                  onClick={() => {
                    setEditingTask(task._id);
                    setEditValues({ name: task.name, text: task.text });
                  }}
                >
                  Изменить
                </Button>
              </Tooltip>
            ] : []),
            
            ...(role === 'admin' || role === 'editor' ? [
              <Tooltip title={completed ? "Отметить как незавершенное" : "Отметить как завершенное"}>
                <Button
                  type="text"
                  icon={completed ? <UndoOutlined /> : <CheckCircleOutlined />}
                  onClick={() => showStatusChangeConfirm(task._id, task.name, !completed)}
                >
                  {completed ? 'Отметить как незавершенное' : 'Отметить как завершенное'}
                </Button>
              </Tooltip>
            ] : []),
            
            ...(role === 'admin' ? [
              <Tooltip title="Удалить">
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteConfirm(task._id, task.name)}
                >
                  Удалить
                </Button>
              </Tooltip>
            ] : [])
          ]}
        >
          <Card.Meta
            title={<Text strong>{task.name}</Text>}
            description={<Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'подробнее' }}>{task.text}</Paragraph>}
          />
        </Card>
      </Badge.Ribbon>
    );
  };

  const incompleteTasks = tasks.filter(task => !task.complete);
  const completeTasks = tasks.filter(task => task.complete);

  const renderNewTaskForm = () => (
    <Card title="Создать новую задачу" style={{ marginBottom: 16 }}>
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Название задачи"
          rules={[{ required: true, message: 'Пожалуйста, введите название задачи' }]}
        >
          <Input 
            placeholder="Введите название задачи" 
            value={newTask.name} 
            onChange={e => setNewTask({ ...newTask, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="text"
          label="Описание задачи"
          rules={[{ required: true, message: 'Пожалуйста, введите описание задачи' }]}
        >
          <TextArea 
            placeholder="Введите описание задачи" 
            rows={4} 
            value={newTask.text} 
            onChange={e => setNewTask({ ...newTask, text: e.target.value })}
          />
        </Form.Item>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={createTask}
        >
          Создать задачу
        </Button>
      </Form>
    </Card>
  );

  return (
    <ConfigProvider theme={customTheme}>
      <div className="task-manager-container">
        <Title level={2}>Управление задачами</Title>
        
        {loading ? (
          <div className="loading-container">
            <Spin size="large" />
            <div className="loading-text">Загрузка задач...</div>
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card 
                title={<Title level={4}>{role === 'admin' || role === 'editor' ? 'Незавершенные задачи' : 'Ваши задачи'}</Title>}
                className="tasks-card"
              >
                {role === 'user' && renderNewTaskForm()}
                
                {incompleteTasks.length > 0 ? (
                  incompleteTasks.map(task => renderTask(task, false))
                ) : (
                  <Empty 
                    description="Нет активных задач" 
                    image={Empty.PRESENTED_IMAGE_SIMPLE} 
                  />
                )}
              </Card>
            </Col>
            
            <Col xs={24} md={12}>
              <Card title={<Title level={4}>Завершенные задачи</Title>} className="tasks-card">
                {completeTasks.length > 0 ? (
                  completeTasks.map(task => renderTask(task, true))
                ) : (
                  <Empty 
                    description="Нет завершенных задач" 
                    image={Empty.PRESENTED_IMAGE_SIMPLE} 
                  />
                )}
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </ConfigProvider>
  );
};

export default TaskManager;
