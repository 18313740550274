import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

import Login from './pages/Login';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedUsername = localStorage.getItem('username');

    if (token) {
      try {
        
        const decodedToken = jwtDecode(token);
        console.log("Full Decoded Token:", JSON.stringify(decodedToken, null, 2) + "  " + token); 
        const role = localStorage.getItem('role')
        setRole(role);
        setUsername(savedUsername); // Используем сохраненный username
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setRole(null);
        setUsername('');
      }
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            role ? (
              <PrivateRoute>
                <Dashboard role={role} username={username} />
              </PrivateRoute>
            ) : (
              <Login />
            )
          }
        />
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
