import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/UserManagement.css';


const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', role: 'user', team: '' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [passwords, setPasswords] = useState({ oldPassword: '', newPassword: '' });
  const [isAddUserVisible, setIsAddUserVisible] = useState(false); // Для сворачивания/разворачивания
  const yourToken = localStorage.getItem("token")
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await axios.get('/api/users', {
        headers: {
          Authorization: `Bearer ${yourToken}`

        }});
        console.log(`${yourToken}`)
      setUsers(res.data);
    } catch (err) {
      console.error('Ошибка при загрузке пользователей', err);
    }
  };

  const addUser = async () => {
    try {
      const res = await axios.post('/api/users', newUser , {
        headers: {
          Authorization: `Bearer ${yourToken}`
        }});
      setUsers([...users, res.data]);
      setNewUser({ username: '', password: '', role: 'user', team: '' });
    } catch (err) {
      console.error('Ошибка при добавлении пользователя', err);
    }
  };

  const updateUser = async (id, updatedData) => {
    try {
      const res = await axios.put(`/api/users/${id}`, updatedData , {
        headers: {
          Authorization: `Bearer ${yourToken}`
        }});
      setUsers(users.map(user => user._id === id ? res.data : user));
      setSelectedUser(null);
    } catch (err) {
      console.error('Ошибка при обновлении пользователя', err);
    }
  };

  const updatePassword = async (id) => {
    try {
      const res = await axios.put(`/api/users/${id}/password`, passwords , {
        headers: {
          Authorization: `Bearer ${yourToken}`
        }});
      alert(res.data.message);
      setPasswords({ oldPassword: '', newPassword: '' });
    } catch (err) {
      console.error('Ошибка при смене пароля', err);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`,{
        headers: {
          Authorization: `Bearer ${yourToken}`
        }});
      setUsers(users.filter(user => user._id !== id));
    } catch (err) {
      console.error('Ошибка при удалении пользователя', err);
    }
  };

  return (
    <div className="user-management">
      <h2>Управление пользователями</h2>

      <div className="toggle-add-user">
        <button onClick={() => setIsAddUserVisible(!isAddUserVisible)}>
          {isAddUserVisible ? 'Скрыть форму добавления пользователя' : 'Показать форму добавления пользователя'}
        </button>
      </div>

      {isAddUserVisible && (
        <div className="add-user">
          <h3>Добавить пользователя</h3>
          <input 
            type="text" 
            placeholder="Логин" 
            value={newUser.username} 
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} 
          />
          <input 
            type="password" 
            placeholder="Пароль" 
            value={newUser.password} 
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} 
          />
          <select 
            value={newUser.role} 
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })} 
          >
            <option value="user">User</option>
            <option value="editor">Editor</option>
            <option value="admin">Admin</option>
          </select>
          {newUser.role === 'user' && (
            <input 
              type="text" 
              placeholder="Команда" 
              value={newUser.team} 
              onChange={(e) => setNewUser({ ...newUser, team: e.target.value })} 
            />
          )}
          <button onClick={addUser}>Добавить</button>
        </div>
      )}

      <div className="user-list">
        <h3>Список пользователей</h3>
        <ul>
          {users.map(user => (
            <li key={user._id}>
              <span>{user.username} - {user.role}</span>
              <button onClick={() => setSelectedUser(user)}>Изменить</button>
              <button onClick={() => deleteUser(user._id)}>Удалить</button>
            </li>
          ))}
        </ul>
      </div>

      {selectedUser && (
        <div className="edit-user">
          <h3>Редактировать пользователя</h3>
          <select 
            value={selectedUser.role} 
            onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })} 
          >
            <option value="user">User</option>
            <option value="editor">Editor</option>
            <option value="admin">Admin</option>
          </select>
          {selectedUser.role === 'user' && (
            <input 
              type="text" 
              placeholder="Команда" 
              value={selectedUser.team} 
              onChange={(e) => setSelectedUser({ ...selectedUser, team: e.target.value })} 
            />
          )}
          <div>
            <label>
              Доступ к статистике:
              <input 
                type="checkbox" 
                checked={selectedUser.statisticsAccess} 
                onChange={(e) => setSelectedUser({ ...selectedUser, statisticsAccess: e.target.checked })} 
              />
            </label>
          </div>

          <h3>Сменить пароль</h3>
          <input 
            type="password" 
            placeholder="Старый пароль" 
            value={passwords.oldPassword} 
            onChange={(e) => setPasswords({ ...passwords, oldPassword: e.target.value })} 
          />
          <input 
            type="password" 
            placeholder="Новый пароль" 
            value={passwords.newPassword} 
            onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })} 
          />
          <button onClick={() => updatePassword(selectedUser._id)}>Сменить пароль</button>

          <button onClick={() => updateUser(selectedUser._id, selectedUser)}>Сохранить изменения</button>
          <button onClick={() => setSelectedUser(null)}>Отмена</button>
        </div>
      )}
    </div>
  );
};

export default UserManagement;

