import React from 'react';
import { Link } from 'react-router-dom';
import Logout from './Logout';
import '../styles/Login.css';
import {jwtDecode} from 'jwt-decode';


const Sidebar = ({}) => {
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  localStorage.setItem('role', decodedToken.role);
  const role = localStorage.getItem('role')
  const username = localStorage.getItem("username")
  const initial = username ? username.charAt(0).toUpperCase() : '';

  return (
    <div className="sidebar">
      <div className="user-section">
        <div className="user-icon">
          <span className="user-initial">{initial}</span>
        </div>
        <p className="username">{username}</p>
      </div>
      <nav>
        <Link to="/dashboard/main">Главная</Link>
        <Link to="/dashboard/statistics">Статистика</Link>
        <Link to="/dashboard/statisticapp">Статистика по приложениям</Link>
        <Link to="/dashboard/yourtask">Задачник</Link>
        <Link to="/dashboard/deepanalyz">Аналитика</Link>
        <Link to="/dashboard/compare">Сравнение</Link>
        {(role === 'editor' || role === 'admin') && (
          <>
            <Link to="/dashboard/templates">Шаблоны</Link>
            <Link to="/dashboard/conditions">Условия</Link>
          </>
        )}
        {role === 'admin' && (
          <>
            <Link to="/dashboard/users">Управление пользователями</Link>
            <Link to="/dashboard/logs">Логи</Link>
            <Link to="/dashboard/apps">Добавление приложений</Link>
            <Link to="/dashboard/test">Тест пушей</Link>
          </>
        )}
      </nav>
      <Logout />
    </div>
  );
};

export default Sidebar;



