import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Logs.css';

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [expandedLogId, setExpandedLogId] = useState(null);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      const res = await axios.get('/api/logs');
      setLogs(res.data);
    } catch (err) {
      console.error('Ошибка при загрузке логов', err);
    }
  };

  const toggleLogDetails = (logId) => {
    setExpandedLogId(expandedLogId === logId ? null : logId);
  };

  const groupedLogs = logs.reduce((acc, log) => {
    (acc[log.level] = acc[log.level] || []).push(log);
    return acc;
  }, {});

  return (
    <div className="logs-panel">
      <h2>Логи системы</h2>
      {['error', 'warn', 'info'].map(level => (
        <div key={level} className="log-group">
          <h3>{level.toUpperCase()}</h3>
          <ul>
            {groupedLogs[level]?.map(log => (
              <li key={log._id}>
                <div className="log-summary" onClick={() => toggleLogDetails(log._id)}>
                  <span>{log.timestamp}</span> - <span>{log.action}</span>
                </div>
                {expandedLogId === log._id && (
                  <div className="log-details">
                    <p>{log.message}</p>
                    <p>Пользователь: {log.user.username}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Logs;
