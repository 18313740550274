// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-panel {
    margin-top: 20px;
  }
  
  .stat-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 12px;
    background-color: rgba(255,255,255,0.2);
    color: rgb(255, 255, 255);
  }
  
  .stat-card p {
    margin: 4px 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Analyz.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uCAAuC;IACvC,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf","sourcesContent":[".statistics-panel {\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .stat-card {\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    padding: 16px;\r\n    margin-bottom: 12px;\r\n    background-color: rgba(255,255,255,0.2);\r\n    color: rgb(255, 255, 255);\r\n  }\r\n  \r\n  .stat-card p {\r\n    margin: 4px 0;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
