import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ConditionComparison = () => {
  const [conditions, setConditions] = useState([]);
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statistics, setStatistics] = useState([]);
  const [selectedCondition1, setSelectedCondition1] = useState("");
  const [selectedCondition2, setSelectedCondition2] = useState("");
  const [comparisonData, setComparisonData] = useState(null);

  const team = localStorage.getItem("team");
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchConditions();
    fetchStatistics();
  }, []);

  const fetchConditions = async () => {
    try {
      const response = await axios.post('/api/get-conditions', { team, role });
      setConditions(response.data);
      setFilteredConditions(response.data); // Изначально показываем все условия
    } catch (error) {
      console.error("Ошибка при получении условий:", error);
    }
  };

  const fetchStatistics = async () => {
    const today = new Date();
    const todayString = today.toISOString().replace(/T.*$/, '');
    console.log(todayString)
    try {
      const response = await axios.post('/api/statistics', {
        appName: "",
        geo: "",
        timeStart: "",
        timeEnd: todayString,
        team,
        role,
        offer: ""
      });
      console.log(response.data)
      setStatistics(response.data);
    } catch (error) {
      console.error('Ошибка при получении статистики:', error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = conditions.filter(condition =>
      condition.name.toLowerCase().includes(value)
    );
    setFilteredConditions(filtered);
  };

  useEffect(() => {
    if (selectedCondition1 && selectedCondition2) {
      compareConditions();
    }
  }, [selectedCondition1, selectedCondition2]);

  const compareConditions = () => {
    const condition1 = statistics.find(stat => stat.conditionName === selectedCondition1);
    const condition2 = statistics.find(stat => stat.conditionName === selectedCondition2);

    if (condition1 && condition2) {
      setComparisonData({
        labels: [
          "Количество пушей",
          "Количество депозитов",
          "Количество регистраций",
        ],
        datasets: [
          {
            label: selectedCondition1,
            data: [
              condition1.pushCount,
              condition1.depCount,
              condition1.regCount,
            ],
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
          {
            label: selectedCondition2,
            data: [
              condition2.pushCount,
              condition2.depCount,
              condition2.regCount,
            ],
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      });
    }
  };

  const handleConditionChange = (condition, setCondition) => {
    setCondition(condition);
  };

  return (
    <div>
      <h2>Сравнение условий</h2>
      {/* Поле поиска */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Поиск условия..."
          value={searchTerm}
          onChange={handleSearch}
          style={{
            width: "97%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            fontSize: "16px",
          }}
        />
      </div>
      <div>
        <label>Условие 1:</label>
        <select value={selectedCondition1} onChange={(e) => handleConditionChange(e.target.value, setSelectedCondition1)}>
          <option value="">Выберите условие</option>
          {filteredConditions.map(condition => (
            <option key={condition._id} value={condition.name}>{condition.name}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Условие 2:</label>
        <select value={selectedCondition2} onChange={(e) => handleConditionChange(e.target.value, setSelectedCondition2)}>
          <option value="">Выберите условие</option>
          {filteredConditions.map(condition => (
            <option key={condition._id} value={condition.name}>{condition.name}</option>
          ))}
        </select>
      </div>
      {comparisonData && (
        <div>
          <h3>Результаты сравнения:</h3>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            {/* Первый график */}
            <div style={{ width: "45%" }}>
              <Bar
                data={comparisonData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                      labels: {
                        color: "#ffffff",
                        font: { size: 14 },
                      },
                    },
                    title: {
                      display: true,
                      text: "Сравнение количественных данных",
                      color: "#ffffff",
                      font: { size: 16 },
                    },
                  },
                  scales: {
                    x: {
                      ticks: { color: "#ffffff", font: { size: 12 } },
                      title: {
                        display: true,
                        text: "Показатели",
                        color: "#ffffff",
                        font: { size: 14 },
                      },
                    },
                    y: {
                      ticks: { color: "#ffffff", font: { size: 12 } },
                      title: {
                        display: true,
                        text: "Значения",
                        color: "#ffffff",
                        font: { size: 14 },
                      },
                    },
                  },
                }}
              />
            </div>
        {/* Второй график: Проценты */}
        <div style={{ width: "45%" }}>
          <Bar
            data={{
              labels: ["Процент депозитов к пушам", "Процент регистраций к пушам"],
              datasets: [
                {
                  label: selectedCondition1,
                  data: [
                    ((statistics.find(stat => stat.conditionName === selectedCondition1)?.depCount || 0) /
                      (statistics.find(stat => stat.conditionName === selectedCondition1)?.pushCount || 1)) * 100,
                    ((statistics.find(stat => stat.conditionName === selectedCondition1)?.regCount || 0) /
                      (statistics.find(stat => stat.conditionName === selectedCondition1)?.pushCount || 1)) * 100,
                  ],
                  backgroundColor: "rgba(54, 162, 235, 0.6)",
                },
                {
                  label: selectedCondition2,
                  data: [
                    ((statistics.find(stat => stat.conditionName === selectedCondition2)?.depCount || 0) /
                      (statistics.find(stat => stat.conditionName === selectedCondition2)?.pushCount || 1)) * 100,
                    ((statistics.find(stat => stat.conditionName === selectedCondition2)?.regCount || 0) /
                      (statistics.find(stat => stat.conditionName === selectedCondition2)?.pushCount || 1)) * 100,
                  ],
                  backgroundColor: "rgba(255, 99, 132, 0.6)",
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                  labels: {
                    color: "#ffffff",
                    font: { size: 14 },
                  },
                },
                title: {
                  display: true,
                  text: "Сравнение процентов",
                  color: "#ffffff",
                  font: { size: 16 },
                },
              },
              scales: {
                x: {
                  ticks: { color: "#ffffff", font: { size: 12 } },
                  title: {
                    display: true,
                    text: "Показатели",
                    color: "#ffffff",
                    font: { size: 14 },
                  },
                },
                y: {
                  ticks: {
                    color: "#ffffff",
                    font: { size: 12 },
                    callback: function (value) {
                      return `${value}%`; // Добавляем символ % к значениям
                    },
                  },
                  title: {
                    display: true,
                    text: "Проценты",
                    color: "#ffffff",
                    font: { size: 14 },
                  },
                },
              },
            }}
          />
        </div>
      </div>
      {/* Таблицы с данными */}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <div style={{ flex: 1, marginRight: "10px", padding: "10px", backgroundColor: "rgba(54, 162, 235, 0.6)", color: "#ffffff", borderRadius: "5px" }}>
          <h4>{selectedCondition1 || "Условие 1"}</h4>
          <p><strong>Заголовок:</strong> {statistics.find(stat => stat.conditionName === selectedCondition1)?.templatetitle || "Нет данных"}</p>
          <p><strong>Текст:</strong> {statistics.find(stat => stat.conditionName === selectedCondition1)?.templatetext || "Нет данных"}</p>
        </div>
        <div style={{ flex: 1, marginLeft: "10px", padding: "10px", backgroundColor: "rgba(255, 99, 132, 0.6)", color: "#ffffff", borderRadius: "5px" }}>
          <h4>{selectedCondition2 || "Условие 2"}</h4>
          <p><strong>Заголовок:</strong> {statistics.find(stat => stat.conditionName === selectedCondition2)?.templatetitle || "Нет данных"}</p>
          <p><strong>Текст:</strong> {statistics.find(stat => stat.conditionName === selectedCondition2)?.templatetext || "Нет данных"}</p>
        </div>
      </div>
    </div>
  )}
</div>
  );
};

export default ConditionComparison;
