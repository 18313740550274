import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { 
  Card, 
  Input, 
  Select, 
  Space, 
  Row, 
  Col, 
  Typography, 
  Divider, 
  Empty, 
  Spin, 
  ConfigProvider, 
  Tabs,
  Alert
} from 'antd';
import { 
  SearchOutlined, 
  SwapOutlined, 
  BarChartOutlined,
  PercentageOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import '../styles/compare-styles.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

// Регистрация необходимых компонентов Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// Кастомная тема
const customTheme = {
  token: {
    colorPrimary: '#1abc9c',
    colorError: '#e74c3c',
    borderRadius: 5,
  },
  components: {
    Card: {
      colorBgContainer: '#ffffff',
      colorText: '#333333',
      colorTextHeading: '#333333',
    },
    Select: {
      colorBgContainer: '#ffffff',
      colorText: '#333333',
    },
    Input: {
      colorBgContainer: '#ffffff',
      colorText: '#333333',
    },
  },
};

const ConditionComparison = () => {
  const [conditions, setConditions] = useState([]);
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statistics, setStatistics] = useState([]);
  const [selectedCondition1, setSelectedCondition1] = useState("");
  const [selectedCondition2, setSelectedCondition2] = useState("");
  const [comparisonData, setComparisonData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  const team = localStorage.getItem("team");
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchConditions();
    fetchStatistics();
  }, []);

  const fetchConditions = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/get-conditions', { team, role });
      setConditions(response.data);
      setFilteredConditions(response.data);
    } catch (error) {
      console.error("Ошибка при получении условий:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatistics = async () => {
    setLoading(true);
    const today = new Date();
    const todayString = today.toISOString().replace(/T.*$/, '');
    
    try {
      const response = await axios.post('/api/statistics', {
        appName: "",
        geo: "",
        timeStart: "",
        timeEnd: todayString,
        team,
        role,
        offer: ""
      });
      setStatistics(response.data);
    } catch (error) {
      console.error('Ошибка при получении статистики:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    const filtered = conditions.filter(condition =>
      condition.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredConditions(filtered);
  };

  useEffect(() => {
    if (selectedCondition1 && selectedCondition2) {
      compareConditions();
    } else {
      setComparisonData(null);
    }
  }, [selectedCondition1, selectedCondition2]);

  // Запуск ререндера графиков при изменении активной вкладки
  useEffect(() => {
    if (activeTab === "1" && comparisonData) {
      // Даем немного времени на рендеринг вкладки перед обновлением графиков
      const timer = setTimeout(() => {
        if (chartRef1.current && chartRef1.current.chartInstance) {
          chartRef1.current.chartInstance.update();
        }
        if (chartRef2.current && chartRef2.current.chartInstance) {
          chartRef2.current.chartInstance.update();
        }
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [activeTab, comparisonData]);

  const compareConditions = () => {
    const condition1 = statistics.find(stat => stat.conditionName === selectedCondition1);
    const condition2 = statistics.find(stat => stat.conditionName === selectedCondition2);
  
    if (condition1 && condition2) {
      setComparisonData({
        labels: [
          "Количество пушей",
          "Количество депозитов",
          "Количество регистраций",
        ],
        datasets: [
          {
            label: selectedCondition1,
            data: [
              condition1.pushCount || 0,
              condition1.depCount || 0,
              condition1.regCount || 0,
            ],
            backgroundColor: "rgba(0, 195, 137, 0.7)",
            borderColor: "rgba(0, 195, 137, 1)",
            borderWidth: 1,
            borderRadius: 4,
            hoverBackgroundColor: "rgba(0, 195, 137, 0.9)",
            hoverBorderColor: "rgba(0, 195, 137, 1)",
          },
          {
            label: selectedCondition2,
            data: [
              condition2.pushCount || 0,
              condition2.depCount || 0,
              condition2.regCount || 0,
            ],
            backgroundColor: "rgba(242, 57, 90, 0.7)",
            borderColor: "rgba(242, 57, 90, 1)",
            borderWidth: 1,
            borderRadius: 4,
            hoverBackgroundColor: "rgba(242, 57, 90, 0.9)",
            hoverBorderColor: "rgba(242, 57, 90, 1)",
          },
        ],
      });
    }
  };

  // Создаем опции для графиков
  
const getChartOptions = (titleText, yAxisTitle = "Значения") => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "center",
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 15,
          color: "#333333",
          font: { size: 12, weight: 'bold', family: "'Arial', sans-serif" },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: true,
        text: titleText,
        color: "#333333",
        font: { size: 14, weight: 'bold', family: "'Arial', sans-serif" },
        padding: { top: 10, bottom: 20 }
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        titleFont: { size: 12, family: "'Arial', sans-serif" },
        bodyFont: { size: 12, family: "'Arial', sans-serif" },
        padding: 8,
        cornerRadius: 4,
        displayColors: true,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              if (yAxisTitle === "Проценты") {
                label += context.parsed.y.toFixed(2) + '%';
              } else {
                label += context.parsed.y;
              }
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: { 
          color: "#333333", 
          font: { size: 11, family: "'Arial', sans-serif" },
          padding: 8,
          maxRotation: 45,
          minRotation: 45,
        },
        title: {
          display: true,
          text: "Показатели",
          color: "#333333",
          font: { size: 12, weight: 'bold', family: "'Arial', sans-serif" },
          padding: { top: 5, bottom: 5 }
        },
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
          tickLength: 8,
        }
      },
      y: {
        beginAtZero: true,
        ticks: { 
          color: "#333333", 
          font: { size: 11, family: "'Arial', sans-serif" },
          padding: 8,
          callback: function (value) {
            return yAxisTitle === "Проценты" ? `${value}%` : value;
          },
        },
        title: {
          display: true,
          text: yAxisTitle,
          color: "#333333",
          font: { size: 12, weight: 'bold', family: "'Arial', sans-serif" },
          padding: { top: 0, bottom: 10 }
        },
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: false,
          tickLength: 8,
        }
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 20,
        top: 0,
        bottom: 0
      }
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 4,
      }
    },
  };
};

  // Подготовка данных для второго графика (процентные показатели)
  const getPercentageData = () => {
    const condition1 = statistics.find(stat => stat.conditionName === selectedCondition1);
    const condition2 = statistics.find(stat => stat.conditionName === selectedCondition2);
    
    if (!condition1 || !condition2) return null;
    
    return {
      labels: ["Процент депозитов к пушам", "Процент регистраций к пушам"],
      datasets: [
        {
          label: selectedCondition1,
          data: [
            ((condition1.depCount || 0) / (condition1.pushCount || 1)) * 100,
            ((condition1.regCount || 0) / (condition1.pushCount || 1)) * 100,
          ],
          backgroundColor: "rgba(0, 195, 137, 0.7)",
          borderColor: "rgba(0, 195, 137, 1)",
          borderWidth: 1,
          borderRadius: 4,
          hoverBackgroundColor: "rgba(0, 195, 137, 0.9)",
          hoverBorderColor: "rgba(0, 195, 137, 1)",
        },
        {
          label: selectedCondition2,
          data: [
            ((condition2.depCount || 0) / (condition2.pushCount || 1)) * 100,
            ((condition2.regCount || 0) / (condition2.pushCount || 1)) * 100,
          ],
          backgroundColor: "rgba(242, 57, 90, 0.7)",
          borderColor: "rgba(242, 57, 90, 1)",
          borderWidth: 1,
          borderRadius: 4,
          hoverBackgroundColor: "rgba(242, 57, 90, 0.9)",
          hoverBorderColor: "rgba(242, 57, 90, 1)",
        },
      ],
    };
  };

  // Получение данных о выбранных условиях
  const getConditionDetails = (conditionName) => {
    return statistics.find(stat => stat.conditionName === conditionName) || {};
  };

  const condition1Details = getConditionDetails(selectedCondition1);
  const condition2Details = getConditionDetails(selectedCondition2);

  return (
    <ConfigProvider theme={customTheme}>
      <div style={{ padding: '20px' }}>
        <Card 
          title={
            <Space>
              <SwapOutlined style={{ fontSize: '20px' }} />
              <Title level={2} style={{ margin: 0, color: '#333333' }}>Сравнение условий</Title>
            </Space>
          }
          style={{ marginBottom: '20px', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Input
              placeholder="Поиск условия..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '20px' }}
              allowClear
            />
            
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Text strong style={{ display: 'block', marginBottom: '8px', color: '#333333' }}>Условие 1:</Text>
                <Select
                  showSearch
                  placeholder="Выберите условие"
                  optionFilterProp="children"
                  value={selectedCondition1}
                  onChange={(value) => setSelectedCondition1(value)}
                  filterOption={(input, option) => 
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  <Option value="">Выберите условие</Option>
                  {filteredConditions.map(condition => (
                    <Option key={condition._id} value={condition.name}>{condition.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} md={12} style={{ marginTop: { xs: '16px', md: '0' } }}>
                <Text strong style={{ display: 'block', marginBottom: '8px', color: '#333333' }}>Условие 2:</Text>
                <Select
                  showSearch
                  placeholder="Выберите условие"
                  optionFilterProp="children"
                  value={selectedCondition2}
                  onChange={(value) => setSelectedCondition2(value)}
                  filterOption={(input, option) => 
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  <Option value="">Выберите условие</Option>
                  {filteredConditions.map(condition => (
                    <Option key={condition._id} value={condition.name}>{condition.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>

            {!selectedCondition1 || !selectedCondition2 ? (
              <Alert
                message="Необходимо выбрать два условия для сравнения"
                description="Пожалуйста, выберите два различных условия из списка выше для их сравнения."
                type="info"
                showIcon
                icon={<InfoCircleOutlined />}
                style={{ marginTop: '20px' }}
              />
            ) : loading ? (
              <div style={{ textAlign: 'center', margin: '40px 0' }}>
                <Spin size="large" />
                <Text style={{ display: 'block', marginTop: '16px', color: '#333333' }}>Загрузка данных для сравнения...</Text>
              </div>
            ) : !comparisonData ? (
              <Empty
                description={<Text style={{ color: '#333333' }}>Нет данных для сравнения</Text>}
                style={{ margin: '40px 0' }}
              />
            ) : (
              <div style={{ marginTop: '20px' }}>
                <Divider style={{ borderColor: '#d9d9d9' }}>
                  <Text strong style={{ color: '#333333', fontSize: '16px' }}>Результаты сравнения</Text>
                </Divider>
                
                <Tabs 
                  defaultActiveKey="1" 
                  centered 
                  style={{ color: '#333333' }}
                  onChange={(key) => setActiveTab(key)}
                >
                  <TabPane 
                    tab={<span><BarChartOutlined /> Количественные показатели</span>} 
                    key="1"
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24} lg={12}>
                      <Card 
                          title="Абсолютные значения" 
                          bordered={true} 
                          className="chart-card"
                          headStyle={{ 
                            fontSize: 14, 
                            fontWeight: 'bold', 
                            borderBottom: '1px solid #f0f0f0', 
                            padding: '10px 16px', 
                            backgroundColor: 'rgb(250, 250, 250)'
                          }}
                          bodyStyle={{ padding: '10px' }}
                        >
                          <div style={{ height: '380px', position: 'relative', padding: '10px' }}>
                            <Bar
                              ref={chartRef1}
                              data={comparisonData}
                              options={getChartOptions("Сравнение количественных данных")}
                            />
                          </div>
                        </Card>
                      </Col>
                      <Col xs={24} lg={12}>
                      <Card 
                          title="Процентные показатели" 
                          bordered={true} 
                          className="chart-card"
                          headStyle={{ 
                            fontSize: 14, 
                            fontWeight: 'bold', 
                            borderBottom: '1px solid #f0f0f0', 
                            padding: '10px 16px', 
                            backgroundColor: 'rgb(250, 250, 250)'
                          }}
                          bodyStyle={{ padding: '10px' }}
                        >
                          <div style={{ height: '380px', position: 'relative', padding: '10px' }}>
                            <Bar
                              ref={chartRef2}
                              data={getPercentageData()}
                              options={getChartOptions("Сравнение процентных показателей", "Проценты")}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane 
                    tab={<span><PercentageOutlined /> Эффективность</span>} 
                    key="2"
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        <Card title="Сравнение эффективности" bordered={true} style={{ backgroundColor: '#fff', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
                          <Row gutter={[16, 16]}>
                            <Col xs={24} md={12}>
                              <Card 
                                title={selectedCondition1} 
                                bordered={true} 
                                style={{ backgroundColor: 'rgba(54, 162, 235, 0.05)', borderColor: 'rgba(54, 162, 235, 0.5)' }}
                                headStyle={{ backgroundColor: 'rgba(54, 162, 235, 0.1)', borderBottom: '1px solid rgba(54, 162, 235, 0.3)' }}
                              >
                                <Space direction="vertical" style={{ width: '100%' }}>
                                  <Paragraph>
                                    <Text strong>Пуши:</Text> {condition1Details.pushCount || 0}
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Депозиты:</Text> {condition1Details.depCount || 0} 
                                    <Text style={{ marginLeft: '8px' }}>
                                      ({(((condition1Details.depCount || 0) / (condition1Details.pushCount || 1)) * 100).toFixed(2)}%)
                                    </Text>
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Регистрации:</Text> {condition1Details.regCount || 0}
                                    <Text style={{ marginLeft: '8px' }}>
                                      ({(((condition1Details.regCount || 0) / (condition1Details.pushCount || 1)) * 100).toFixed(2)}%)
                                    </Text>
                                  </Paragraph>
                                  <Divider style={{ margin: '8px 0', borderColor: 'rgba(54, 162, 235, 0.2)' }} />
                                  <Paragraph>
                                    <Text strong>Заголовок:</Text> {condition1Details.templatetitle || "Нет данных"}
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Текст:</Text> {condition1Details.templatetext || "Нет данных"}
                                  </Paragraph>
                                </Space>
                              </Card>
                            </Col>
                            <Col xs={24} md={12}>
                              <Card 
                                title={selectedCondition2} 
                                bordered={true} 
                                style={{ backgroundColor: 'rgba(255, 99, 132, 0.05)', borderColor: 'rgba(255, 99, 132, 0.5)' }}
                                headStyle={{ backgroundColor: 'rgba(255, 99, 132, 0.1)', borderBottom: '1px solid rgba(255, 99, 132, 0.3)' }}
                              >
                                <Space direction="vertical" style={{ width: '100%' }}>
                                  <Paragraph>
                                    <Text strong>Пуши:</Text> {condition2Details.pushCount || 0}
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Депозиты:</Text> {condition2Details.depCount || 0}
                                    <Text style={{ marginLeft: '8px' }}>
                                      ({(((condition2Details.depCount || 0) / (condition2Details.pushCount || 1)) * 100).toFixed(2)}%)
                                    </Text>
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Регистрации:</Text> {condition2Details.regCount || 0}
                                    <Text style={{ marginLeft: '8px' }}>
                                      ({(((condition2Details.regCount || 0) / (condition2Details.pushCount || 1)) * 100).toFixed(2)}%)
                                    </Text>
                                  </Paragraph>
                                  <Divider style={{ margin: '8px 0', borderColor: 'rgba(255, 99, 132, 0.2)' }} />
                                  <Paragraph>
                                    <Text strong>Заголовок:</Text> {condition2Details.templatetitle || "Нет данных"}
                                  </Paragraph>
                                  <Paragraph>
                                    <Text strong>Текст:</Text> {condition2Details.templatetext || "Нет данных"}
                                  </Paragraph>
                                </Space>
                              </Card>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            )}
          </Space>
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default ConditionComparison;