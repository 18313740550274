import React, { useState, useEffect } from "react";
import axios from "axios";
import '../styles/Analyz.css';

const StatisticsComponent = () => {
  const [statistics, setStatistics] = useState([]); // Вся статистика
  const [filteredStatistics, setFilteredStatistics] = useState([]); // Фильтрованная статистика
  const [conditions, setConditions] = useState([]); // Список условий
  const [selectedCondition, setSelectedCondition] = useState(""); 
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredConditions, setFilteredConditions] = useState([]);
  const itemsPerPage = 10;
  const team = localStorage.getItem('team');
  const role = localStorage.getItem('role');// Выбранное условие

  // Функция для получения статистики
  const fetchStatistics = async () => {
    const today = new Date();
    const todayString = today.toISOString().replace(/T.*$/, '');
    try {
      const response = await axios.post("/api/statistics", {
        appName: "",
        geo: "",
        timeStart: "",
        timeEnd: todayString,
        team,
        role,
        offer: "",
      });
      setStatistics(response.data);
      setFilteredStatistics(response.data); // Изначально показываем всю статистику
    } catch (error) {
      console.error("Ошибка при получении статистики:", error);
    }
  };

  // Функция для получения условий
  const fetchConditions = async () => {
    try {
      const response = await axios.post('/api/get-conditions', {team, role});// Эндпоинт для получения всех условий
      setConditions(response.data);
      setFilteredConditions(response.data);
    } catch (error) {
      console.error("Ошибка при получении условий:", error);
    }
  };

  const filteredStat = filteredStatistics
   .filter(statistics => statistics.conditionName.toLowerCase().includes(searchTerm.toLowerCase()))
   .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  console.log(searchTerm);
  let pagesStat
  if (searchTerm == ""){
    pagesStat = filteredStatistics

  }
  else {
    pagesStat = filteredStat
   
  }
  const totalPages = Math.ceil(pagesStat.length / itemsPerPage);

  const changePage = (newPage) => {
   if (newPage > 0 && newPage <= totalPages) {
    setCurrentPage(newPage);
   }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = conditions.filter(condition =>
      condition.name.toLowerCase().includes(value)
    );
    setFilteredConditions(filtered);
  };

  // Обработчик изменения выбранного условия
  const handleConditionChange = (e) => {
    const conditionName = e.target.value;
    setSelectedCondition(conditionName);

    if (conditionName === "") {
      setFilteredStatistics(filteredStatistics); // Если ничего не выбрано, показываем всю статистику
    } else {
      console.log(conditionName)
      const filtered = filteredStatistics.filter(
        (stat) => stat.conditionName === conditionName
      );
      setFilteredStatistics(filtered);
    }
  };

  useEffect(() => {
    fetchConditions();
    fetchStatistics();
  }, []);

  const exportToCSV = (filteredStat, filename = 'filteredStat.csv') => {
    if (!filteredStat.length) {
      console.log('No data to export.');
      return;
    }
  
    // Удаление ключа appName из объектов
    const cleanedData = filteredStat.map(item => {
      const { AppTeam, ...rest } = item; // Убираем appName
      return rest;
    });
  
    // Получение заголовков (ключей объекта)
    const headers = Object.keys(cleanedData[0]).join(',');
  
    // Преобразование данных в строки CSV
    const rows = cleanedData.map(item =>
      Object.values(item)
        .map(value => (value === undefined || value === null || value === '' ? 'нет' : value)) // Замена undefined или null на "нет"
        .map(value => `"${value}"`) // Кавычки для значений
        .join(',')
    );
  
    // Объединение заголовков и строк данных
    const csvContent = [headers, ...rows].join('\n');
  
    // Создание и скачивание файла CSV
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
  
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
    <h1>Выбор условий</h1>
    {/* Поле поиска */}
    <div style={{ marginBottom: "20px" }}>
      <input
        type="text"
        placeholder="Поиск условия..."
        value={searchTerm}
        onChange={handleSearch}
        style={{
          width: "97%",
          padding: "6px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          fontSize: "16px",
        }}
      />
    </div>

      
      <select onChange={handleConditionChange} value={selectedCondition}>
        <option value="">Все условия</option>
        {filteredConditions.map((condition) => (
          <option key={condition._id} value={condition.name}>
            {condition.name}
          </option>
        ))}
      </select>      
      <button style={{ marginLeft: '10px' }} onClick={() => exportToCSV(filteredStat)}>
        Скачать CSV
      </button>


      {filteredStat.length > 0 ? (
        <div className="statistics-panel">
          <h2>Детализированная статистика</h2>
          {filteredStat.map((stat, index) => (
            <div key={index} className="stat-card">
              <p><strong>Название условия:</strong> {stat.conditionName}</p>
              <p><strong>Название приложения:</strong> {stat.appName}</p>
              <p><strong>Заголовок:</strong> {stat.templatetitle}</p>
              <p><strong>Текст:</strong> {stat.templatetext}</p>
              <p><strong>Кол-во пушей:</strong> {stat.pushCount}</p>
              <p><strong>Кол-во деп:</strong> {stat.depCount}</p>
              <p><strong>Кол-во регистраций:</strong> {stat.regCount}</p>
              <p><strong>Процент депов к пушам:</strong> {((stat.depCount / stat.pushCount) * 100).toFixed(2)}%</p>
              <p><strong>Процент регистраций к пушам:</strong> {((stat.regCount / stat.pushCount) * 100).toFixed(2)}%</p>
            </div>
          ))}      
          <div className="pagination">
          <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>Назад</button>
          <span>Страница {currentPage} из {totalPages}</span>
          <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>Вперед</button>
        </div>
        </div>
        
      ) : (
        <p>Нет данных для отображения.</p>
      )}
    </div>
  );
};

export default StatisticsComponent;
