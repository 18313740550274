import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomPagination from './CustomPagination';
import { 
  Table, 
  Button, 
  Form, 
  Input, 
  Select, 
  Card, 
  Space, 
  Typography, 
  Tabs, 
  Divider, 
  message, 
  Popconfirm, 
  ConfigProvider,
  Row,
  Col,
  Tag,
  Tooltip,
  Collapse,
  Badge
} from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  SaveOutlined, 
  CloseOutlined, 
  AppstoreOutlined,
  DownOutlined,
  UpOutlined,
  FireOutlined,
  SendOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import '../styles/Apps.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

// Обновленная тема с белым фоном
const customTheme = {
  token: {
    colorPrimary: '#e74c3c',
    colorSuccess: '#20c997',
    colorError: '#e74c3c',
    borderRadius: 5,
    fontSize: 16,
  },
  components: {
    Card: {
      colorBgContainer: '#ffffff',
      colorText: '#333333',
      colorTextHeading: '#333333',
    },
    Table: {
      colorBgContainer: '#ffffff',
      colorText: '#333333',
      colorTextHeading: '#333333',
      headerBg: '#f5f5f5',
    },
    Button: {
      defaultColor: '#ffffff',
      defaultBg: '#e74c3c',
      defaultBorderColor: '#e74c3c',
    },
    Tabs: {
      colorText: '#333333',
      inkBarColor: '#e74c3c',
    },
    Form: {
      labelColor: '#333333',
    },
  },
};

const Apps = () => {
  const [form] = Form.useForm();
  const [appType, setAppType] = useState('Android/IOS/PWA');
  const [apps, setApps] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentAppId, setCurrentAppId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  // Получение списка приложений при загрузке компонента
  useEffect(() => {
    fetchApps();
  }, []);

  const paginatedApps = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return apps.slice(startIndex, endIndex);
  };

  const fetchApps = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/api/apps');
      setApps(res.data);
    } catch (err) {
      console.error('Ошибка при получении приложений:', err);
      message.error('Не удалось загрузить список приложений');
    } finally {
      setLoading(false);
    }
  };

  const handleTypeChange = (value) => {
    setAppType(value);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    const submissionData = {
      type: appType,
      name: values.appName,
      team: values.team,
    };

    if (appType === 'Android/IOS/PWA') {
      submissionData.firebaseConfig = {
        type: values.type,
        project_id: values.project_id,
        private_key_id: values.private_key_id,
        private_key: values.private_key,
        client_email: values.client_email,
        client_id: values.client_id,
        auth_uri: values.auth_uri,
        token_uri: values.token_uri,
        auth_provider_x509_cert_url: values.auth_provider_x509_cert_url,
        client_x509_cert_url: values.client_x509_cert_url,
        universe_domain: values.universe_domain,
        bundle: values.bundle,
      };
    } else if (appType === 'Telegram') {
      submissionData.apiCode = values.apiCode;
      submissionData.link = values.link;
    }

    setLoading(true);
    try {
      if (editMode) {
        await axios.put(`/api/apps/${currentAppId}`, submissionData);
        message.success('Приложение успешно обновлено');
        setEditMode(false);
      } else {
        await axios.post('/api/apps', submissionData);
        message.success('Приложение успешно добавлено');
      }

      form.resetFields();
      fetchApps();
      setShowForm(false);
    } catch (err) {
      console.error('Ошибка при добавлении или обновлении приложения:', err);
      message.error('Произошла ошибка. Проверьте введенные данные.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/api/apps/${id}`);
      setApps(apps.filter((app) => app._id !== id));
      message.success('Приложение успешно удалено');
    } catch (err) {
      console.error('Ошибка при удалении приложения:', err);
      message.error('Не удалось удалить приложение');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (app) => {
    const formValues = {
      appName: app.name,
      team: app.team || '',
    };

    if (app.type === 'Android/IOS/PWA' && app.firebaseConfig) {
      Object.assign(formValues, {
        type: app.firebaseConfig.type || '',
        project_id: app.firebaseConfig.project_id || '',
        private_key_id: app.firebaseConfig.private_key_id || '',
        private_key: app.firebaseConfig.private_key || '',
        client_email: app.firebaseConfig.client_email || '',
        client_id: app.firebaseConfig.client_id || '',
        auth_uri: app.firebaseConfig.auth_uri || '',
        token_uri: app.firebaseConfig.token_uri || '',
        auth_provider_x509_cert_url: app.firebaseConfig.auth_provider_x509_cert_url || '',
        client_x509_cert_url: app.firebaseConfig.client_x509_cert_url || '',
        universe_domain: app.firebaseConfig.universe_domain || '',
        bundle: app.firebaseConfig.bundle || '',
      });
    } else if (app.type === 'Telegram') {
      Object.assign(formValues, {
        link: app.link || '',
        apiCode: app.apiCode || '',
      });
    }

    form.setFieldsValue(formValues);
    setAppType(app.type);
    setEditMode(true);
    setCurrentAppId(app._id);
    setShowForm(true);
  };

  const toggleForm = () => {
    if (showForm && editMode) {
      // Если закрываем форму в режиме редактирования, сбрасываем режим и форму
      setEditMode(false);
      form.resetFields();
    }
    setShowForm(!showForm);
  };

  const cancelEdit = () => {
    setEditMode(false);
    form.resetFields();
    setShowForm(false);
  };

  // Получение иконки для типа приложения
  const getAppTypeIcon = (type) => {
    switch (type) {
      case 'Android/IOS/PWA':
        return <FireOutlined style={{ color: '#f5722d' }} />;
      case 'Telegram':
        return <SendOutlined style={{ color: '#1890ff' }} />;
      default:
        return <AppstoreOutlined />;
    }
  };

  // Получение тега для типа приложения
  const getAppTypeTag = (type) => {
    switch (type) {
      case 'Android/IOS/PWA':
        return <Tag color="volcano">{type}</Tag>;
      case 'Telegram':
        return <Tag color="blue">{type}</Tag>;
      default:
        return <Tag>{type}</Tag>;
    }
  };

  // Определение столбцов для таблицы
  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: '35%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => (
        <div className="first-column">
          <Text strong>{text}</Text>
        </div>
      ),
      className: 'column-header',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      width: '30%',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => (
        <div className="type-cell">
          {getAppTypeIcon(type)}
          {getAppTypeTag(type)}
        </div>
      ),
      className: 'column-header',
    },
    {
      title: 'Команда',
      dataIndex: 'team',
      key: 'team',
      width: '15%',
      render: (team) => (
        <div className="team-cell">
          {team || '-'}
        </div>
      ),
      className: 'column-header',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <div className="action-cell">
          <div className="action-buttons">
            <Button 
              icon={<EditOutlined />} 
              onClick={() => handleEdit(record)}
              size="middle"
              style={{ backgroundColor: '#20c997', borderColor: '#20c997', color: 'white' }}
              type="primary"
            >
              Ред.
            </Button>
            <Popconfirm
              title="Удаление приложения"
              description="Вы уверены, что хотите удалить это приложение?"
              onConfirm={() => handleDelete(record._id)}
              okText="Да"
              cancelText="Нет"
              placement="topRight"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <Button 
                icon={<DeleteOutlined />} 
                size="middle"
                danger
                type="primary"
              >
                Удалить
              </Button>
            </Popconfirm>
          </div>
        </div>
      ),
      className: 'column-header',
    },
  ];

  // Обработчики для пагинации
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  // Рендеринг формы для Android/IOS/PWA
  const renderAndroidForm = () => (
    <div style={{ display: appType === 'Android/IOS/PWA' ? 'block' : 'none' }}>
      <Collapse 
        defaultActiveKey={['1']} 
        expandIconPosition="end"
        bordered={true}
        style={{ marginBottom: 16 }}
      >
        <Panel 
          header={
            <Space>
              <FireOutlined />
              <Text strong>Firebase конфигурация</Text>
              <Badge count="Необходимо" style={{ backgroundColor: '#e74c3c' }} />
            </Space>
          } 
          key="1"
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item name="type" label="Тип аккаунта" rules={[{ required: true, message: 'Введите тип аккаунта' }]}>
                <Input placeholder="Тип аккаунта" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="project_id" label="Project ID" rules={[{ required: true, message: 'Введите Project ID' }]}>
                <Input placeholder="Project ID" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item name="private_key_id" label="Private Key ID" rules={[{ required: true, message: 'Введите Private Key ID' }]}>
                <Input placeholder="Private Key ID" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="client_id" label="Client ID" rules={[{ required: true, message: 'Введите Client ID' }]}>
                <Input placeholder="Client ID" />
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item name="private_key" label="Private Key" rules={[{ required: true, message: 'Введите Private Key' }]}>
            <Input.TextArea rows={3} placeholder="Private Key" />
          </Form.Item>
          
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item name="client_email" label="Client Email" rules={[{ required: true, message: 'Введите Client Email' }]}>
                <Input placeholder="Client Email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="bundle" label="Bundle" rules={[{ required: true, message: 'Введите Bundle' }]}>
                <Input placeholder="com.example.app" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item name="auth_uri" label="Auth URI" rules={[{ required: true, message: 'Введите Auth URI' }]}>
                <Input placeholder="Auth URI" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="token_uri" label="Token URI" rules={[{ required: true, message: 'Введите Token URI' }]}>
                <Input placeholder="Token URI" />
              </Form.Item>
            </Col>
          </Row>
          
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Form.Item name="auth_provider_x509_cert_url" label="Auth Provider URL" 
                rules={[{ required: true, message: 'Введите Auth Provider URL' }]}>
                <Input placeholder="Auth Provider URL" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="client_x509_cert_url" label="Client Cert URL" 
                rules={[{ required: true, message: 'Введите Client Cert URL' }]}>
                <Input placeholder="Client Cert URL" />
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item name="universe_domain" label="Universe Domain" rules={[{ required: true, message: 'Введите Universe Domain' }]}>
            <Input placeholder="Universe Domain" />
          </Form.Item>
        </Panel>
      </Collapse>
    </div>
  );

  // Рендеринг формы для Telegram
  const renderTelegramForm = () => (
    <div style={{ display: appType === 'Telegram' ? 'block' : 'none' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item name="link" label="Telegram Link" rules={[{ required: true, message: 'Введите Telegram Link' }]}>
            <Input placeholder="https://t.me/yourbot" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name="apiCode" label="API Code" rules={[{ required: true, message: 'Введите API Code' }]}>
            <Input placeholder="API Code" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  return (
    <ConfigProvider theme={customTheme}>
      <div className="main-content">
        <Card 
          title={
            <Space align="center">
              <AppstoreOutlined style={{ fontSize: 18 }} />
              <span style={{ fontSize: 18, fontWeight: 500 }}>
                {editMode ? 'Редактировать приложение' : 'Управление приложениями'}
              </span>
            </Space>
          }
          bordered={true}
          className="app-card"
          extra={
            <Button
              type="primary"
              onClick={toggleForm}
              icon={showForm ? <UpOutlined /> : <PlusOutlined />}
            >
              {showForm ? 'Скрыть форму' : 'Добавить приложение'}
            </Button>
          }
        >
          {showForm && (
            <div>
              <Divider style={{ margin: '10px 0 20px 0' }} />
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ appType: 'Android/IOS/PWA' }}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={8}>
                    <Form.Item label="Тип приложения" name="appType">
                      <Select 
                        value={appType} 
                        onChange={handleTypeChange}
                        style={{ width: '100%' }}
                      >
                        <Option value="Android/IOS/PWA">
                          <Space>
                            <FireOutlined />
                            Android/IOS/PWA
                          </Space>
                        </Option>
                        <Option value="Telegram">
                          <Space>
                            <SendOutlined />
                            Telegram
                          </Space>
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item name="appName" label="Название приложения" rules={[{ required: true, message: 'Введите название приложения' }]}>
                      <Input placeholder="Название приложения" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item name="team" label="Команда" rules={[{ required: true, message: 'Введите команду' }]}>
                      <Input placeholder="Команда" />
                    </Form.Item>
                  </Col>
                </Row>
                
                <Tabs 
                  defaultActiveKey={appType} 
                  onChange={handleTypeChange}
                  style={{ marginBottom: 16 }}
                  type="card"
                >
                  <TabPane 
                    tab={
                      <Space>
                        <FireOutlined />
                        Android/IOS/PWA
                      </Space>
                    } 
                    key="Android/IOS/PWA"
                  >
                    {renderAndroidForm()}
                  </TabPane>
                  <TabPane 
                    tab={
                      <Space>
                        <SendOutlined />
                        Telegram
                      </Space>
                    } 
                    key="Telegram"
                  >
                    {renderTelegramForm()}
                  </TabPane>
                </Tabs>
                
                <Form.Item>
                  <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {editMode && (
                      <Button 
                        onClick={cancelEdit} 
                        icon={<CloseOutlined />}
                        danger
                      >
                        Отмена
                      </Button>
                    )}
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      loading={loading}
                      icon={editMode ? <SaveOutlined /> : <PlusOutlined />}
                    >
                      {editMode ? 'Сохранить изменения' : 'Добавить приложение'}
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          )}
        </Card>
    
        <Card
          title={
            <Space align="center">
              <AppstoreOutlined />
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                Список приложений 
                <Badge 
                  count={apps.length} 
                  style={{ marginLeft: 10, backgroundColor: '#e74c3c' }} 
                  showZero
                />
              </span>
            </Space>
          }
          bordered={true}
          className="app-card table-card"
          extra={
            <Button 
              icon={<ReloadOutlined />} 
              onClick={fetchApps} 
              loading={loading}
            >
              Обновить
            </Button>
          }
        >
          <Table
            columns={columns}
            dataSource={paginatedApps().map(app => ({ ...app, key: app._id }))}
            loading={loading}
            pagination={false}
            scroll={{ x: 'max-content' }}
            size="middle"
            rowClassName={() => 'table-row'}
          />
          
          {/* Используем компонент CustomPagination вместо самописной пагинации */}
          <CustomPagination 
            total={apps.length}
            current={currentPage}
            pageSize={pageSize}
            onChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </Card>
      </div>
    </ConfigProvider>
  );
};

export default Apps;