import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Card, 
  Typography, 
  Select, 
  Button, 
  Space, 
  message, 
  Empty, 
  Spin,
  Divider,
  Row,
  Col,
  Alert
} from 'antd';
import { 
  SendOutlined, 
  BellOutlined, 
  AppstoreOutlined, 
  UserOutlined
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { Option } = Select;

const TestPush = () => {
  const [users, setUsers] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedApp, setSelectedApp] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingApps, setLoadingApps] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  // Получаем список пользователей и приложений
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingUsers(true);
        const [responseTG, responseAPA, appsResponse] = await Promise.all([
          axios.get('/api/telegram/users', {
            headers: {
              Authorization: `Bearer ${token}` // Или другой формат, который ожидает ваш бэкенд
            }
          }).catch(err => ({ data: [] })),
          axios.get('/api/apa/users', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }).catch(err => ({ data: [] })),
          axios.get('/api/apps', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }).catch(err => {
            throw new Error('Не удалось загрузить список приложений');
          })
        ]);

        // Объединяем пользователей из обоих источников
        const combinedUsers = [...responseTG.data, ...responseAPA.data];
        setUsers(combinedUsers);
        setApps(appsResponse.data);
        
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'Произошла ошибка при загрузке данных');
      } finally {
        setLoadingUsers(false);
        setLoadingApps(false);
      }
    };

    fetchData();
  }, []);

  // Функция для отправки тестового пуша
  const sendTestPush = async () => {
    if (!selectedUser || !selectedApp) {
      message.warning('Пожалуйста, выберите пользователя и приложение');
      return;
    }
    
    setIsSending(true);
    try {
      await axios.post(`/api/test/send-test-push/${selectedUser}`, {
        appID: selectedApp
      });
      message.success('Пуш-уведомление успешно отправлено');
    } catch (error) {
      console.error('Error sending push:', error);
      message.error('Ошибка при отправке пуш-уведомления');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: 'calc(100vh - 64px)' }}>
      <Col xs={24} sm={20} md={16} lg={14} xl={12}>
        <Card
          title={
            <div style={{ textAlign: 'center' }}>
              <Space align="center">
                <BellOutlined />
                <Title level={3} style={{ margin: 0 }}>
                  Тестирование пуш-уведомлений
                </Title>
              </Space>
            </div>
          }
          style={{ width: '100%', boxShadow: '0 1px 2px rgba(0,0,0,0.05)' }}
        >
          {error && (
            <Alert
              message="Ошибка"
              description={error}
              type="error"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div style={{ marginBottom: 16 }}>
                <Space align="center">
                  <UserOutlined />
                  <Text strong>
                    Выберите пользователя:
                  </Text>
                </Space>
                <Select
                  placeholder="Выберите пользователя"
                  style={{ width: '100%', marginTop: 8 }}
                  value={selectedUser}
                  onChange={setSelectedUser}
                  loading={loadingUsers}
                  showSearch
                  optionFilterProp="children"
                  notFoundContent={loadingUsers ? <Spin size="small" /> : <Empty description="Нет пользователей" />}
                  disabled={isSending}
                >
                  {users.map((user) => (
                    <Option key={user._id} value={user._id}>
                      {user.username || user.af_id || 'Пользователь без имени'}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            
            <Col span={24}>
              <div style={{ marginBottom: 16 }}>
                <Space align="center">
                  <AppstoreOutlined />
                  <Text strong>
                    Выберите приложение:
                  </Text>
                </Space>
                <Select
                  placeholder="Выберите приложение"
                  style={{ width: '100%', marginTop: 8 }}
                  value={selectedApp}
                  onChange={setSelectedApp}
                  loading={loadingApps}
                  showSearch
                  optionFilterProp="children"
                  notFoundContent={loadingApps ? <Spin size="small" /> : <Empty description="Нет приложений" />}
                  disabled={isSending}
                >
                  {apps.map((app) => (
                    <Option key={app._id} value={app._id}>
                      {app.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>

          <Divider />

          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={sendTestPush}
                disabled={!selectedUser || !selectedApp}
                loading={isSending}
                size="large"
                block
              >
                Отправить тестовое уведомление
              </Button>
              
              <Button
                onClick={() => {
                  setSelectedUser('');
                  setSelectedApp('');
                }}
                disabled={isSending || (!selectedUser && !selectedApp)}
                block
              >
                Сбросить
              </Button>
            </Space>
            
            {(!users.length || !apps.length) && !loadingUsers && !loadingApps && (
              <Alert
                message="Внимание"
                description={
                  !users.length && !apps.length
                    ? "Не найдены пользователи и приложения"
                    : !users.length
                    ? "Не найдены пользователи"
                    : "Не найдены приложения"
                }
                type="warning"
                showIcon
                style={{ marginTop: 16 }}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default TestPush;