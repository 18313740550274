import React, { useState, useEffect } from 'react';
import '../styles/Apps.css';
import axios from 'axios';

const Apps = () => {
  const [appType, setAppType] = useState('Android/IOS/PWA'); // По умолчанию выбран PWA
  const [formData, setFormData] = useState({
    appName: '',
    type: '',
    project_id: '',
    private_key_id: '',
    private_key: '',
    client_email: '',
    client_id: '',
    auth_uri: '',
    token_uri: '',
    auth_provider_x509_cert_url: '',
    client_x509_cert_url: '',
    universe_domain: '',
    bundle: '',
    link: '',
    apiCode: '',
  });

  const [apps, setApps] = useState([]); // Состояние для списка приложений
  const [editMode, setEditMode] = useState(false); // Режим редактирования
  const [currentAppId, setCurrentAppId] = useState(null); // Текущий ID приложения для редактирования
  const [showForm, setShowForm] = useState(false); // Видимость формы

  const handleTypeChange = (e) => {
    setAppType(e.target.value);
    setFormData({
      ...formData,
      appName: '',
      type: '',
      project_id: '',
      private_key_id: '',
      private_key: '',
      client_email: '',
      client_id: '',
      auth_uri: '',
      token_uri: '',
      auth_provider_x509_cert_url: '',
      client_x509_cert_url: '',
      universe_domain: '',
      bundle: '',
      link: '',
      apiCode: '',
      team: '',
    });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Получение списка приложений при загрузке компонента
  useEffect(() => {
    const fetchApps = async () => {
      try {
        const res = await axios.get('/api/apps');
        setApps(res.data);
      } catch (err) {
        console.error('Ошибка при получении приложений:', err);
      }
    };

    fetchApps();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = {
      type: appType,
      name: formData.appName,
    };

    if (appType === 'Android/IOS/PWA') {
      submissionData.team = formData.team;
      submissionData.firebaseConfig = {
        type: formData.type,
        project_id: formData.project_id,
        private_key_id: formData.private_key_id,
        private_key: formData.private_key,
        client_email: formData.client_email,
        client_id: formData.client_id,
        auth_uri: formData.auth_uri,
        token_uri: formData.token_uri,
        auth_provider_x509_cert_url: formData.auth_provider_x509_cert_url,
        client_x509_cert_url: formData.client_x509_cert_url,
        universe_domain: formData.universe_domain,
        bundle: formData.bundle,
      };
    } else if (appType === 'Telegram') {
      submissionData.apiCode = formData.apiCode;
      submissionData.link = formData.link;
      submissionData.team = formData.team;
    }

    try {
      if (editMode) {
        await axios.put(`/api/apps/${currentAppId}`, submissionData);
        setEditMode(false); // Выход из режима редактирования после обновления
      } else {
        await axios.post('/api/apps', submissionData);
      }

      setFormData({
        appName: '',
        type: '',
        project_id: '',
        private_key_id: '',
        private_key: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        universe_domain: '',
        bundle: '',
        link: '',
        apiCode: '',
      });

      // Повторный запрос списка приложений после добавления
      const res = await axios.get('/api/apps');
      setApps(res.data);
    } catch (err) {
      console.error('Ошибка при добавлении или обновлении приложения:', err);
    }
  };

  // Удаление приложения
  const handleDelete = async (id) => {
    try {
      console.log(id)
      await axios.delete(`/api/apps/${id}`);
      setApps(apps.filter((app) => app._id !== id)); // Удалить приложение из списка
    } catch (err) {
      console.error('Ошибка при удалении приложения:', err);
    }
  };

  // Установка приложения для редактирования
  const handleEdit = (app) => {
    setFormData({
      appName: app.name,
      type: app.type,
      project_id: app.firebaseConfig?.project_id || '',
      private_key_id: app.firebaseConfig?.private_key_id || '',
      private_key: app.firebaseConfig?.private_key || '',
      client_email: app.firebaseConfig?.client_email || '',
      client_id: app.firebaseConfig?.client_id || '',
      auth_uri: app.firebaseConfig?.auth_uri || '',
      token_uri: app.firebaseConfig?.token_uri || '',
      auth_provider_x509_cert_url: app.firebaseConfig?.auth_provider_x509_cert_url || '',
      client_x509_cert_url: app.firebaseConfig?.client_x509_cert_url || '',
      universe_domain: app.firebaseConfig?.universe_domain || '',
      bundle: app.firebaseConfig?.bundle || '',
      link: app.link || '',
      apiCode: app.apiCode || '',
      team: app.team || ''
    });
    setAppType(app.type);
    setEditMode(true);
    setCurrentAppId(app._id);
    setShowForm(true); // Открыть форму при редактировании
  };

  const toggleForm = () => {
    setShowForm(!showForm); // Переключаем видимость формы
  };

  return (
    <div className="apps-container">
      <h2>{editMode ? 'Редактировать приложение' : 'Добавить приложение'}</h2>
      <button onClick={toggleForm}>
        {showForm ? 'Скрыть форму' : 'Показать форму'}
      </button>
      {showForm && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="appType">Тип приложения:</label>
            <select id="appType" value={appType} onChange={handleTypeChange}>
              <option value="Android/IOS/PWA">Android/IOS/PWA</option>
              <option value="Telegram">Telegram</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="appName">Название приложения:</label>
            <input
              type="text"
              id="appName"
              name="appName"
              value={formData.appName}
              onChange={handleInputChange}
              required
            />
          </div>

          {appType === 'Android/IOS/PWA' && (
            <>
              {/* Поля для Android/IOS/PWA */}
              <div className="form-group">
                <label htmlFor="type">Тип аккаунта:</label>
                <input
                  type="text"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="project_id">Project ID:</label>
                <input
                  type="text"
                  id="project_id"
                  name="project_id"
                  value={formData.project_id}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="private_key_id">Private Key ID:</label>
                <input
                  type="text"
                  id="private_key_id"
                  name="private_key_id"
                  value={formData.private_key_id}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="private_key">Private Key:</label>
                <input
                  type="text"
                  id="private_key"
                  name="private_key"
                  value={formData.private_key}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="client_email">Client Email:</label>
                <input
                  type="text"
                  id="client_email"
                  name="client_email"
                  value={formData.client_email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="client_id">Client ID:</label>
                <input
                  type="text"
                  id="client_id"
                  name="client_id"
                  value={formData.client_id}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="auth_uri">Auth URI:</label>
                <input
                  type="text"
                  id="auth_uri"
                  name="auth_uri"
                  value={formData.auth_uri}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="token_uri">Token URI:</label>
                <input
                  type="text"
                  id="token_uri"
                  name="token_uri"
                  value={formData.token_uri}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="auth_provider_x509_cert_url">Auth Provider x509 Cert URL:</label>
                <input
                  type="text"
                  id="auth_provider_x509_cert_url"
                  name="auth_provider_x509_cert_url"
                  value={formData.auth_provider_x509_cert_url}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="client_x509_cert_url">Client x509 Cert URL:</label>
                <input
                  type="text"
                  id="client_x509_cert_url"
                  name="client_x509_cert_url"
                  value={formData.client_x509_cert_url}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="universe_domain">Universe Domain:</label>
                <input
                  type="text"
                  id="universe_domain"
                  name="universe_domain"
                  value={formData.universe_domain}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="bundle">Bundle:</label>
                <input
                  type="text"
                  id="bundle"
                  name="bundle"
                  value={formData.bundle}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="team">Team:</label>
                <input
                  type="text"
                  id="team"
                  name="team"
                  value={formData.team}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </>
          )}

          {appType === 'Telegram' && (
            <>
              {/* Поля для Telegram */}
              <div className="form-group">
                <label htmlFor="link">Telegram Link:</label>
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={formData.link}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="apiCode">API Code:</label>
                <input
                  type="text"
                  id="apiCode"
                  name="apiCode"
                  value={formData.apiCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="team">Team:</label>
                <input
                  type="text"
                  id="team"
                  name="team"
                  value={formData.team}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </>
          )}

          <button type="submit">{editMode ? 'Обновить приложение' : 'Добавить приложение'}</button>
        </form>
      )}

      <h3>Список приложений</h3>
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Тип</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {apps.map((app) => (
            <tr key={app._id}>
              <td>{app.name}</td>
              <td>{app.type}</td>
              <td className="button-container">
                <button className = "button-apps" onClick={() => handleEdit(app)}>Редактировать</button>
                <button className = "button-apps" onClick={() => handleDelete(app._id)}>Удалить</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Apps;
