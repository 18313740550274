import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Avatar, Typography, Divider } from 'antd';
import { 
  HomeOutlined, 
  BarChartOutlined, 
  AppstoreOutlined, 
  CheckSquareOutlined, 
  LineChartOutlined, 
  SwapOutlined, 
  FileTextOutlined, 
  SettingOutlined,
  UserOutlined,
  FileSearchOutlined,
  AppstoreAddOutlined,
  NotificationOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';
import '../styles/Login.css';

const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  localStorage.setItem('role', decodedToken.role);
  const role = localStorage.getItem('role');
  const username = localStorage.getItem("username");
  const initial = username ? username.charAt(0).toUpperCase() : '';
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    window.location.href = '/login';
  };

  return (
    <Sider 
      collapsible 
      collapsed={collapsed} 
      onCollapse={value => setCollapsed(value)}
      style={{ 
        overflow: 'auto', 
        height: '100vh', 
        position: 'fixed', 
        left: 0,
        backgroundColor: '#001529'
      }}
    >
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: '16px 0',
        margin: '16px 0'
      }}>
        <Avatar 
          size={collapsed ? 36 : 64} 
          style={{ backgroundColor: '#1890ff' }}
        >
          {initial}
        </Avatar>
        {!collapsed && (
          <Typography.Text 
            className='nickname'
            style={{ 
              color: 'white', 
              marginTop: 8 
            }}
          >
            {username}
          </Typography.Text>
        )}
      </div>
      
      <Divider style={{ borderColor: 'rgba(255,255,255,0.1)', margin: '0 0 8px 0' }} />
      
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/dashboard/main" icon={<HomeOutlined />}>
          <Link to="/dashboard/main">Главная</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/statistics" icon={<BarChartOutlined />}>
          <Link to="/dashboard/statistics">Статистика</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/statisticapp" icon={<AppstoreOutlined />}>
          <Link to="/dashboard/statisticapp">Статистика по приложениям</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/yourtask" icon={<CheckSquareOutlined />}>
          <Link to="/dashboard/yourtask">Задачник</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/deepanalyz" icon={<LineChartOutlined />}>
          <Link to="/dashboard/deepanalyz">Аналитика</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/compare" icon={<SwapOutlined />}>
          <Link to="/dashboard/compare">Сравнение</Link>
        </Menu.Item>
        
        {(role === 'editor' || role === 'admin') && (
          <>
            <Menu.Item key="/dashboard/templates" icon={<FileTextOutlined />}>
              <Link to="/dashboard/templates">Шаблоны</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/conditions" icon={<SettingOutlined />}>
              <Link to="/dashboard/conditions">Условия</Link>
            </Menu.Item>
          </>
        )}
        
        {role === 'admin' && (
          <>
            <Divider style={{ borderColor: 'rgba(255,255,255,0.1)', margin: '8px 0' }} />
            <Menu.Item key="/dashboard/users" icon={<UserOutlined />}>
              <Link to="/dashboard/users">Управление пользователями</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/logs" icon={<FileSearchOutlined />}>
              <Link to="/dashboard/logs">Логи</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/apps" icon={<AppstoreAddOutlined />}>
              <Link to="/dashboard/apps">Добавление приложений</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/test" icon={<NotificationOutlined />}>
              <Link to="/dashboard/test">Тест пушей</Link>
            </Menu.Item>
          </>
        )}
        
        <Divider style={{ borderColor: 'rgba(255,255,255,0.1)', margin: '8px 0' }} />
        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
          Выйти
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;